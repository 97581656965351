/*========================================================
        DARK LAYOUT
=========================================================*/
a:focus {
  outline: none;
}

.font-small-1 {
  font-size: 0.7rem !important;
}

.font-small-2 {
  font-size: 0.8rem !important;
}

.font-small-3 {
  font-size: 0.9rem !important;
}

.font-medium-1 {
  font-size: 1.1rem !important;
}

.font-medium-2 {
  font-size: 1.2rem !important;
}

.font-medium-3 {
  font-size: 1.3rem !important;
}

.font-medium-4 {
  font-size: 1.4rem !important;
}

.font-medium-5 {
  font-size: 1.5rem !important;
}

.font-large-1 {
  font-size: 2rem !important;
}

.font-large-2 {
  font-size: 3rem !important;
}

.font-large-3 {
  font-size: 4rem !important;
}

.font-large-4 {
  font-size: 5rem !important;
}

.font-large-5 {
  font-size: 6rem !important;
}

.text-bold-300 {
  font-weight: 300;
}

.text-bold-400 {
  font-weight: 400;
}

.text-bold-500 {
  font-weight: 500;
}

.text-bold-600 {
  font-weight: 600;
}

.text-bold-700 {
  font-weight: 700;
}

.text-italic {
  font-style: italic;
}

.text-highlight {
  padding: 4px 6px;
}

code {
  padding: 0.1rem 0.4rem;
  font-size: 90%;
  color: #e83e8c;
  background-color: #eeeeee;
  border-radius: 0.25rem;
}

pre {
  background-color: #f7f7f9;
}

pre code {
  background-color: transparent !important;
}

.bd-example-row .row + .row {
  margin-top: 1rem;
}

.bd-example-row .row > .col,
.bd-example-row .row > [class^="col-"] {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  background-color: rgba(86, 61, 124, 0.15);
  border: 1px solid rgba(86, 61, 124, 0.2);
}

.bd-example-row .flex-items-top,
.bd-example-row .flex-items-middle,
.bd-example-row .flex-items-bottom {
  min-height: 6rem;
  background-color: rgba(255, 0, 0, 0.1);
}

.bd-example-row-flex-cols .row {
  min-height: 10rem;
  background-color: rgba(255, 0, 0, 0.1);
}

.bd-highlight {
  background-color: rgba(86, 61, 124, 0.15);
  border: 1px solid rgba(86, 61, 124, 0.15);
}

.example-container {
  width: 800px;
  width: 100%;
  padding-right: 14px;
  padding-left: 14px;
  margin-right: auto;
  margin-left: auto;
}

.example-row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -14px;
  margin-left: -14px;
}

.example-content-main {
  position: relative;
  width: 100%;
  padding-right: 14px;
  padding-left: 14px;
}

@media (min-width: 576px) {
  .example-content-main {
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 50%;
      -ms-flex: 0 0 50%;
      flex: 0 0 50%;
      max-width: 50%;
  }
}

@media (min-width: 992px) {
  .example-content-main {
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 66.66667%;
      -ms-flex: 0 0 66.66667%;
      flex: 0 0 66.66667%;
      max-width: 66.66667%;
  }
}

.example-content-secondary {
  position: relative;
  width: 100%;
  padding-right: 14px;
  padding-left: 14px;
}

@media (min-width: 576px) {
  .example-content-secondary {
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 50%;
      -ms-flex: 0 0 50%;
      flex: 0 0 50%;
      max-width: 50%;
  }
}

@media (min-width: 992px) {
  .example-content-secondary {
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 33.33333%;
      -ms-flex: 0 0 33.33333%;
      flex: 0 0 33.33333%;
      max-width: 33.33333%;
  }
}

.table-white-space th,
.table-white-space td {
  white-space: nowrap;
}

.table thead th {
  vertical-align: top;
}

.table th,
.table td {
  padding: 1rem;
  vertical-align: middle;
}

.table th {
  font-size: 0.85rem;
}

.table-inverse {
  color: #ffffff;
  background-color: #00cfe8;
}

.table-hover-animation {
  background-color: #f8f8f8;
}

.table-hover-animation thead th {
  border-top: 2px solid #f8f8f8;
  border-bottom: 0;
  background-color: #ffffff;
}

.table-hover-animation th,
.table-hover-animation td {
  border: 0;
}

.table-hover-animation tbody tr {
  -webkit-transition: all 0.25s ease;
  transition: all 0.25s ease;
  background-color: #ffffff;
}

.table-hover-animation tbody tr:hover {
  -webkit-transform: translateY(-4px);
  -ms-transform: translateY(-4px);
  transform: translateY(-4px);
}

@media screen and (-ms-high-contrast: active),
(-ms-high-contrast: none) {
  .table-hover-animation tr:hover {
      border-bottom-color: #f8f8f8;
  }
}

.form-control::-webkit-input-placeholder {
  font-size: 0.85rem;
  color: rgba(34, 41, 47, 0.4);
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.form-control::-moz-placeholder {
  font-size: 0.85rem;
  color: rgba(34, 41, 47, 0.4);
  -moz-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.form-control:-ms-input-placeholder {
  font-size: 0.85rem;
  color: rgba(34, 41, 47, 0.4);
  -ms-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.form-control::-ms-input-placeholder {
  font-size: 0.85rem;
  color: rgba(34, 41, 47, 0.4);
  -ms-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.form-control::placeholder {
  font-size: 0.85rem;
  color: rgba(34, 41, 47, 0.4);
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.form-control:-ms-input-placeholder {
  color: #c5c5c5;
}

.form-control:focus::-webkit-input-placeholder {
  -webkit-transform: translate(5px);
  transform: translate(5px);
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.form-control:focus::-moz-placeholder {
  transform: translate(5px);
  -moz-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.form-control:focus:-ms-input-placeholder {
  -ms-transform: translate(5px);
  transform: translate(5px);
  -ms-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.form-control:focus::-ms-input-placeholder {
  -ms-transform: translate(5px);
  transform: translate(5px);
  -ms-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.form-control:focus::placeholder {
  -webkit-transform: translate(5px);
  -ms-transform: translate(5px);
  transform: translate(5px);
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.form-control:focus ~ .form-control-position i {
  color: #28c76f;
}

/* Form specific Scss */
label {
  color: #464646;
  font-size: 0.85rem;
  margin-bottom: 0;
  padding-left: 0.2rem;
}

textarea.form-control {
  line-height: 1.6rem;
  font-size: 1rem;
}

.form-group {
  margin-bottom: 1.5rem;
}

.form-control {
  border: 1px solid #d9d9d9;
  color: #5f5f5f;
}

.form-section {
  color: #b8c2cc;
  line-height: 3rem;
  margin-bottom: 20px;
  border-bottom: 1px solid #b8c2cc;
}

.form-section i {
  font-size: 20px;
  margin-left: 5px;
  margin-right: 10px;
}

/* .form-body{
  overflow: hidden;
} */
.form-actions {
  border-top: 1px solid #dfdfdf;
  padding: 20px 0;
  margin-top: 20px;
}

.form-actions.filled {
  background-color: #f8f8f8;
}

.form-actions.center {
  text-align: center;
}

.form-actions.right {
  text-align: right;
}

.form-actions.top {
  border-top: 0;
  border-bottom: 1px solid #dfdfdf;
  margin-top: 0;
  margin-bottom: 20px;
}

@media (max-width: 767.98px) {
  .form-actions .buttons-group {
      float: left !important;
      margin-bottom: 10px;
  }
}

@media (min-width: 576px) {
  .form-horizontal .form-group .label-control {
      text-align: right;
  }
}

.form-control.form-control-lg {
  padding: 1rem;
}

.form-control.form-control-lg::-webkit-input-placeholder {
  font-size: 1.25rem;
}

.form-control.form-control-lg::-moz-placeholder {
  font-size: 1.25rem;
}

.form-control.form-control-lg:-ms-input-placeholder {
  font-size: 1.25rem;
}

.form-control.form-control-lg::-ms-input-placeholder {
  font-size: 1.25rem;
}

.form-control.form-control-lg::placeholder {
  font-size: 1.25rem;
}

.form-control.form-control-sm {
  padding: 0.6rem;
}

.form-control.form-control-sm::-webkit-input-placeholder {
  font-size: 0.7rem;
}

.form-control.form-control-sm::-moz-placeholder {
  font-size: 0.7rem;
}

.form-control.form-control-sm:-ms-input-placeholder {
  font-size: 0.7rem;
}

.form-control.form-control-sm::-ms-input-placeholder {
  font-size: 0.7rem;
}

.form-control.form-control-sm::placeholder {
  font-size: 0.7rem;
}

.custom-file-label {
  padding: 0.375rem 0.75rem;
}

.custom-file-label::after {
  padding: 0.375rem 0.75rem;
  height: auto;
}

select.form-control:not([multiple="multiple"]) {
  background-image: url("./images/icons/arrow-down.png");
  background-position: calc(100% - 12px) 13px, calc(100% - 20px) 13px, 100% 0;
  background-size: 12px 12px, 10px 10px;
  background-repeat: no-repeat;
  -webkit-appearance: none;
  -moz-appearance: none;
  padding-right: 1.5rem;
}

.custom-select {
  -moz-appearance: none;
  -webkit-appearance: none;
}

.input-group-addon {
  background-color: #eceff1;
  border-color: #babfc7;
}

.custom-control-label::before,
.custom-control-label::after {
  width: 1.143rem;
  height: 1.143rem;
  top: 0.08rem;
}

.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  background-color: #7367f0;
  border-color: #7367f0;
}

/* Floating label Group */
.form-label-group {
  position: relative;
  margin-bottom: 1.5rem;
}

.form-label-group .form-control-lg ~ label {
  font-size: 1.25rem;
  padding: 1rem;
}

.form-label-group .form-control-sm ~ label {
  font-size: 0.7rem;
}

.form-label-group > label {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  padding: 0.6rem;
  pointer-events: none;
  cursor: text;
  color: rgba(34, 41, 47, 0.4);
  font-size: 0.85rem;
  opacity: 0;
}

.form-label-group > input,
.form-label-group textarea {
  padding: 0.6rem;
}

.form-label-group > input:focus:not(:placeholder-shown) ~ label,
.form-label-group > input:not(:active):not(:placeholder-shown) ~ label,
.form-label-group textarea:focus:not(:placeholder-shown) ~ label,
.form-label-group textarea:not(:active):not(:placeholder-shown) ~ label {
  color: #7367f0 !important;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  opacity: 1;
}

.form-label-group > input:not(:focus):not(:placeholder-shown) ~ label,
.form-label-group textarea:not(:focus):not(:placeholder-shown) ~ label {
  color: rgba(34, 41, 47, 0.4) !important;
}

.form-label-group > input:not(:placeholder-shown) ~ label,
.form-label-group textarea:not(:placeholder-shown) ~ label {
  padding: 0.25rem 0;
  font-size: 0.7rem;
  top: -20px;
  left: 3px;
}

.form-label-group.has-icon-left > label {
  left: 36px;
}

/* Vuexy Custom Checkbox */
.vs-checkbox-con {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  margin: 0.25rem 0;
}

.vs-checkbox-con input {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 200;
  cursor: pointer;
  top: 0;
}

.vs-checkbox-con input:checked ~ .vs-checkbox {
  -webkit-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
  border-color: #7367f0;
}

.vs-checkbox-con input:checked ~ .vs-checkbox .vs-checkbox--check {
  -webkit-transform: translate(0);
  -ms-transform: translate(0);
  transform: translate(0);
  background-color: #7367f0;
}

.vs-checkbox-con input:checked ~ .vs-checkbox .vs-checkbox--check .vs-icon {
  -webkit-transform: translate(0);
  -ms-transform: translate(0);
  transform: translate(0);
  line-height: 1.2;
  opacity: 1;
}

.vs-checkbox-con input:checked ~ .vs-checkbox.vs-checkbox-sm .vs-checkbox--check .vs-icon {
  font-size: 0.75rem;
}

.vs-checkbox-con input:checked ~ .vs-checkbox.vs-checkbox-lg .vs-checkbox--check .vs-icon {
  font-size: 1.2rem;
}

.vs-checkbox-con input:active:checked + .vs-checkbox .vs-checkbox--check {
  -webkit-transform: translate(3px);
  -ms-transform: translate(3px);
  transform: translate(3px);
  background-color: #7367f0;
}

.vs-checkbox-con input:active:checked + .vs-checkbox .vs-checkbox--check .vs-icon {
  opacity: 1;
  -webkit-transform: translate(6px);
  -ms-transform: translate(6px);
  transform: translate(6px);
}

.vs-checkbox-con input:disabled {
  cursor: default;
  pointer-events: none;
}

.vs-checkbox-con input:disabled + .vs-checkbox {
  cursor: default;
  opacity: 0.5;
}

.vs-checkbox-con .vs-checkbox {
  cursor: pointer;
  position: relative;
  width: 20px;
  height: 20px;
  border-width: 2px;
  border-style: solid;
  border-color: #b8c2cc;
  border-radius: 2px;
  -webkit-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg);
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  overflow: hidden;
  margin-right: 0.5rem;
}

.vs-checkbox-con .vs-checkbox.vs-checkbox-sm {
  width: 15px;
  height: 15px;
}

.vs-checkbox-con .vs-checkbox.vs-checkbox-lg {
  width: 24px;
  height: 24px;
}

.vs-checkbox-con .vs-checkbox .vs-checkbox--check {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  -webkit-transform: translate(100%);
  -ms-transform: translate(100%);
  transform: translate(100%);
  -webkit-transform-origin: right;
  -ms-transform-origin: right;
  transform-origin: right;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  z-index: 10;
}

.vs-checkbox-con .vs-checkbox .vs-checkbox--check .vs-icon {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  color: #ffffff;
}

/* Vuexy Custom Radio */
.vs-radio-con {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  margin: 0.25rem 0;
}

.vs-radio-con input {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 200;
  cursor: pointer;
}

.vs-radio-con input:checked ~ .vs-radio {
  -webkit-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
  outline: 0;
}

.vs-radio-con input:checked ~ .vs-radio .vs-radio--circle {
  background: #7367f0;
  box-shadow: 0 3px 12px 0 rgba(115, 103, 240, 0.4);
  opacity: 1;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}

.vs-radio-con input:checked ~ .vs-radio .vs-radio--border {
  opacity: 0;
  -webkit-transform: scale(0.3);
  -ms-transform: scale(0.3);
  transform: scale(0.3);
}

.vs-radio-con input:disabled {
  cursor: default;
  pointer-events: none;
}

.vs-radio-con input:disabled + .vs-radio {
  cursor: default;
  opacity: 0.5;
}

.vs-radio-con input:active ~ .vs-radio {
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
}

.vs-radio-con .vs-radio {
  cursor: pointer;
  position: relative;
  width: 18px;
  height: 18px;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  margin-right: 0.5rem;
}

.vs-radio-con .vs-radio.vs-radio-sm {
  width: 13px;
  height: 13px;
}

.vs-radio-con .vs-radio.vs-radio-lg {
  width: 22px;
  height: 22px;
}

.vs-radio-con .vs-radio .vs-radio--border,
.vs-radio-con .vs-radio .vs-radio--circle {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  -webkit-transition: all 0.25s ease;
  transition: all 0.25s ease;
  top: 0;
  border-radius: 50%;
}

.vs-radio-con .vs-radio .vs-radio--border {
  background: transparent;
  border: 2px solid #b8c2cc;
}

.vs-radio-con .vs-radio .vs-radio--circle {
  opacity: 0;
  -webkit-transform: scale(0.1);
  -ms-transform: scale(0.1);
  transform: scale(0.1);
}

/* Switches */
.custom-switch {
  padding-left: 0;
  /*For Switch label*/
  /*For Switch Handle Animation*/
}

.custom-switch .custom-control-label {
  height: 1.571rem;
  width: 3.1rem;
  padding: 0;
  /* For bg color of switch*/
  /*For Switch handle*/
  /*For Switch text*/
}

.custom-switch .custom-control-label::before {
  border: none;
  background-color: #e2e2e2;
  height: 1.571rem;
  left: -0.2857rem;
  box-shadow: none !important;
  -webkit-transition: all 0.25s ease;
  transition: all 0.25s ease;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  top: 0;
  left: 0;
}

.custom-switch .custom-control-label:after {
  position: absolute;
  top: 2px;
  left: 2px;
  box-shadow: none;
  background-color: #ffffff;
  -webkit-transition: all 0.25s ease;
  transition: all 0.25s ease;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.custom-switch .custom-control-label .switch-text-left,
.custom-switch .custom-control-label .switch-text-right,
.custom-switch .custom-control-label .switch-icon-left,
.custom-switch .custom-control-label .switch-icon-right {
  position: absolute;
  top: 5px;
  font-size: 0.7rem;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.custom-switch .custom-control-label .switch-text-left,
.custom-switch .custom-control-label .switch-icon-left {
  left: 7px;
  color: #ffffff;
}

.custom-switch .custom-control-label .switch-text-right,
.custom-switch .custom-control-label .switch-icon-right {
  right: 7px;
}

.custom-switch .custom-control-label:focus {
  outline: 0;
}

.custom-switch .switch-label {
  padding-left: 1rem;
}

.custom-switch .custom-control-input:checked ~ .custom-control-label::before {
  box-shadow: none;
}

.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  -webkit-transform: translateX(1.4rem);
  -ms-transform: translateX(1.4rem);
  transform: translateX(1.4rem);
}

.custom-switch .custom-control-input:not(:checked) ~ .custom-control-label .switch-text-left {
  opacity: 0;
}

.custom-switch .custom-control-input:not(:checked) ~ .custom-control-label .switch-text-right {
  opacity: 1;
}

.custom-switch .custom-control-input:checked ~ .custom-control-label .switch-text-right {
  opacity: 0;
}

.custom-switch .custom-control-input:checked ~ .custom-control-label .switch-text-left {
  opacity: 1;
}

.custom-switch .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  background-color: #e2e2e2;
}

.custom-switch.switch-md .custom-control-label {
  width: 4rem;
  height: 1.571rem;
}

.custom-switch.switch-md .custom-control-label::before {
  width: 4rem;
  border: none;
  background-color: #e2e2e2;
  height: 1.571rem;
  left: -0.2857rem;
  box-shadow: none !important;
  -webkit-transition: all 0.25s ease;
  transition: all 0.25s ease;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.custom-switch.switch-md .custom-control-label:after {
  box-shadow: none;
  background-color: #ffffff;
  -webkit-transition: all 0.25s ease;
  transition: all 0.25s ease;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  position: absolute;
  top: 2px;
  left: -2px;
}

.custom-switch.switch-md .custom-control-label .switch-text-right,
.custom-switch.switch-md .custom-control-label .switch-icon-right {
  right: 9px;
}

.custom-switch.switch-md .custom-control-label .switch-text-left,
.custom-switch.switch-md .custom-control-label .switch-icon-left {
  left: 5px;
}

.custom-switch.switch-md .custom-control-input:checked ~ .custom-control-label::after {
  -webkit-transform: translateX(2.3rem);
  -ms-transform: translateX(2.3rem);
  transform: translateX(2.3rem);
}

.custom-switch.switch-lg .custom-control-label {
  width: 5.5rem;
  height: 1.571rem;
}

.custom-switch.switch-lg .custom-control-label::before {
  width: 5.5rem;
  border: none;
  background-color: #e2e2e2;
  height: 1.571rem;
  left: -0.2857rem;
  box-shadow: none !important;
  -webkit-transition: all 0.25s ease;
  transition: all 0.25s ease;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.custom-switch.switch-lg .custom-control-label:after {
  position: absolute;
  top: 2px;
  left: -2px;
  box-shadow: none;
  background-color: #ffffff;
  -webkit-transition: all 0.25s ease;
  transition: all 0.25s ease;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.custom-switch.switch-lg .custom-control-label .switch-text-right,
.custom-switch.switch-lg .custom-control-label .switch-icon-right {
  right: 10px;
}

.custom-switch.switch-lg .custom-control-input:checked ~ .custom-control-label::after {
  -webkit-transform: translateX(3.8rem);
  -ms-transform: translateX(3.8rem);
  transform: translateX(3.8rem);
}

/* Textarea with Counter */
.counter-value {
  background-color: #7367f0;
  color: #ffffff;
  padding: 1px 6px;
  font-size: 0.6rem;
  border-radius: 0 0 5px 5px;
  margin-right: 1rem;
}

/* Input box with icon divider */
.form-group.input-divider-left .form-control-position i {
  padding: 0.2rem 0.5rem;
  border-right: 1px solid #d9d9d9;
}

.form-group.input-divider-right .form-control-position i {
  padding: 0.2rem 0.5rem;
  border-left: 1px solid #d9d9d9;
}

/* input-groups-lg and input-groups-sm  */
.input-group-lg .form-control,
.input-group-sm .form-control {
  padding: 0.7rem;
}

/* Bootstrap touchspin */
.bootstrap-touchspin.input-group {
  width: 8.4rem;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.bootstrap-touchspin.input-group .form-control {
  padding: 5px;
  height: auto;
  border: 0;
  background-color: #f8f8f8;
  border-radius: 5px !important;
  text-align: center;
  font-weight: 500;
}

.bootstrap-touchspin.input-group .form-control:focus {
  z-index: 1;
  box-shadow: none;
}

.bootstrap-touchspin .bootstrap-touchspin-injected {
  margin: 0 !important;
}

.bootstrap-touchspin .bootstrap-touchspin-injected .bootstrap-touchspin-down,
.bootstrap-touchspin .bootstrap-touchspin-injected .bootstrap-touchspin-up {
  padding: 0;
  min-width: 22px;
  min-height: 22px;
  border-radius: 5px !important;
  position: relative;
}

.bootstrap-touchspin .bootstrap-touchspin-injected .bootstrap-touchspin-down i,
.bootstrap-touchspin .bootstrap-touchspin-injected .bootstrap-touchspin-up i {
  font-size: 0.8rem;
  position: relative;
  top: -1px;
}

.bootstrap-touchspin .bootstrap-touchspin-injected .bootstrap-touchspin-down {
  left: 12px;
}

.bootstrap-touchspin .bootstrap-touchspin-injected .bootstrap-touchspin-up {
  right: 12px;
}

.bootstrap-touchspin.disabled-touchspin .bootstrap-touchspin-down,
.bootstrap-touchspin.disabled-touchspin .bootstrap-touchspin-up {
  background-color: rgba(0, 0, 0, 0.5) !important;
  cursor: default;
  opacity: 0.5;
}

.bootstrap-touchspin.input-group-lg {
  width: 9.375rem;
}

.bootstrap-touchspin.input-group-lg .touchspin.form-control {
  height: auto !important;
}

.bootstrap-touchspin.input-group-lg .bootstrap-touchspin-down i,
.bootstrap-touchspin.input-group-lg .bootstrap-touchspin-up i {
  font-size: 1rem;
}

.bootstrap-touchspin.input-group-sm {
  width: 6.25rem;
}

.bootstrap-touchspin.input-group-sm .touchspin.form-control {
  height: auto !important;
}

.bootstrap-touchspin.input-group-sm .bootstrap-touchspin-injected .bootstrap-touchspin-down,
.bootstrap-touchspin.input-group-sm .bootstrap-touchspin-injected .bootstrap-touchspin-up {
  min-width: 16px;
  min-height: 16px;
  padding-top: 2.2px;
}

.bootstrap-touchspin.input-group-sm .bootstrap-touchspin-injected .bootstrap-touchspin-down i,
.bootstrap-touchspin.input-group-sm .bootstrap-touchspin-injected .bootstrap-touchspin-up i {
  font-size: 0.6rem;
}

.btn.disabled-max-min {
  background-color: rgba(0, 0, 0, 0.5) !important;
  cursor: default;
}

/* Number Type Input Box Scss for - Remove arrow on hover */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

/* Number Type Input Box Scss for Touchspin - Remove arrow for firefox */
.bootstrap-touchspin.input-group input[type="number"] {
  -moz-appearance: textfield;
}

@media screen and (-ms-high-contrast: active),
(-ms-high-contrast: none) {
  .form-label-group label {
      display: none;
  }

  select.form-control:not([multiple="multiple"]) {
      background: none;
  }

  .custom-file-label::after {
      padding: 1.34rem 0.7rem 1.25rem;
  }
}

.picker__input.form-control {
  background-color: #ffffff;
}

.input-group .form-control {
  height: auto;
}

.input-group .input-group-prepend .dropdown-toggle::after,
.input-group .input-group-append .dropdown-toggle::after {
  left: 0;
}

.input-group .input-group-prepend .vs-checkbox-con,
.input-group .input-group-prepend .vs-checkbox,
.input-group .input-group-prepend .vs-radio-con,
.input-group .input-group-prepend .vs-radio,
.input-group .input-group-append .vs-checkbox-con,
.input-group .input-group-append .vs-checkbox,
.input-group .input-group-append .vs-radio-con,
.input-group .input-group-append .vs-radio {
  margin: 0;
}

.input-group.input-group-lg .form-control::-webkit-input-placeholder {
  font-size: 1.25rem;
}

.input-group.input-group-lg .form-control::-moz-placeholder {
  font-size: 1.25rem;
}

.input-group.input-group-lg .form-control:-ms-input-placeholder {
  font-size: 1.25rem;
}

.input-group.input-group-lg .form-control::-ms-input-placeholder {
  font-size: 1.25rem;
}

.input-group.input-group-lg .form-control::placeholder {
  font-size: 1.25rem;
}

.input-group.input-group-lg .input-group-prepend .btn,
.input-group.input-group-lg .input-group-append .btn {
  line-height: 1.3;
  font-size: 1.3rem;
}

.input-group.input-group-sm .form-control::-webkit-input-placeholder {
  font-size: 0.7rem;
}

.input-group.input-group-sm .form-control::-moz-placeholder {
  font-size: 0.7rem;
}

.input-group.input-group-sm .form-control:-ms-input-placeholder {
  font-size: 0.7rem;
}

.input-group.input-group-sm .form-control::-ms-input-placeholder {
  font-size: 0.7rem;
}

.input-group.input-group-sm .form-control::placeholder {
  font-size: 0.7rem;
}

.btn:disabled {
  cursor: default;
}

.btn.btn-white {
  color: #626262;
}

.btn.btn-white:hover,
.btn.btn-white:focus,
.btn.btn-white:active {
  color: #626262 !important;
}

.btn[class*="bg-gradient-"]:hover {
  box-shadow: 0 8px 25px -8px #aaaaaa;
}

.btn.btn-secondary {
  color: #ffffff;
}

.btn.btn-secondary:hover {
  box-shadow: 0 8px 25px -8px #6c757d;
}

.btn.btn-dark:hover {
  box-shadow: 0 8px 25px -8px #343a40;
}

.btn.btn-light:hover {
  box-shadow: 0 8px 25px -8px #f8f9fa;
}

.btn.btn-outline-secondary,
.btn.btn-outline-dark,
.btn.btn-outline-light {
  border: 1px solid;
}

.btn.btn-flat-secondary:hover {
  background-color: rgba(108, 117, 125, 0.1);
}

.btn.btn-flat-secondary:active,
.btn.btn-flat-secondary:focus {
  background: #6c757d;
}

.btn.btn-flat-dark:hover {
  background-color: rgba(52, 58, 64, 0.1);
}

.btn.btn-flat-dark:active,
.btn.btn-flat-dark:focus {
  background: #343a40;
  color: #ffffff;
}

.btn.btn-flat-light:hover {
  background-color: rgba(248, 249, 250, 0.1);
}

.btn.btn-flat-light:active,
.btn.btn-flat-light:focus {
  background: #f8f9fa;
}

.btn[class*="btn-outline-"] {
  padding-top: calc(0.9rem - 1px);
  padding-bottom: calc(0.9rem - 1px);
}

.btn[class*="btn-outline-"].btn-sm {
  padding-top: calc(0.5rem - 1px);
  padding-bottom: calc(0.5rem - 1px);
}

.btn.btn-relief-secondary {
  background-color: #6c757d;
  box-shadow: inset 0 -3px 0 0 rgba(0, 0, 0, 0.2);
  color: #ffffff;
}

.btn.btn-relief-secondary:hover {
  color: #ffffff;
}

.btn.btn-relief-secondary:active,
.btn.btn-relief-secondary:focus {
  outline: none;
  -webkit-transform: translateY(3px);
  -ms-transform: translateY(3px);
  transform: translateY(3px);
}

.btn.btn-relief-light {
  box-shadow: inset 0 -3px 0 0 rgba(0, 0, 0, 0.2);
  color: #ffffff;
}

.btn.btn-relief-light:hover {
  color: #ffffff;
}

.btn.btn-relief-light:active,
.btn.btn-relief-light:focus {
  outline: none;
  -webkit-transform: translateY(3px);
  -ms-transform: translateY(3px);
  transform: translateY(3px);
}

.btn.btn-relief-dark {
  background-color: #343a40;
  box-shadow: inset 0 -3px 0 0 rgba(255, 255, 255, 0.1);
  color: #ffffff;
}

.btn.btn-relief-dark:hover {
  color: #ffffff;
}

.btn.btn-relief-dark:active,
.btn.btn-relief-dark:focus {
  outline: none;
  -webkit-transform: translateY(3px);
  -ms-transform: translateY(3px);
  transform: translateY(3px);
}

.btn.btn-icon {
  padding: 0.715rem 0.736rem;
}

.btn.btn-sm.btn-icon {
  padding: 0.5rem;
}

.btn.btn-lg.btn-icon {
  padding: 1rem;
}

.btn.btn-square {
  border-radius: 0;
}

.btn.btn-round {
  border-radius: 2rem;
}

.btn:focus,
.btn.focus,
.btn:active,
.btn.active {
  outline: none;
  box-shadow: none !important;
}

.btn.btn-icon i {
  font-size: 1.1rem;
}

.btn.btn-outline-white:hover,
.btn-white:hover {
  color: #22292f !important;
}

button,
html [type="button"],
[type="reset"],
[type="submit"] {
  cursor: pointer;
}

.waves-button-input {
  padding: unset;
}

.collapse-icon .card-header {
  position: relative;
}

.collapse-icon [data-toggle="collapse"]:after {
  position: absolute;
  top: 48%;
  right: 20px;
  margin-top: -8px;
  font-family: "feather";
  content: "\e845";
  -webkit-transition: all 300ms linear 0s;
  transition: all 300ms linear 0s;
  font-size: 0.9rem;
}

.collapse-icon [data-toggle="collapse"]:before {
  position: absolute;
  top: 48%;
  right: 20px;
  margin-top: -8px;
  font-family: "feather";
  content: "\e842";
  -webkit-transition: all 300ms linear 0s;
  transition: all 300ms linear 0s;
  font-size: 0.9rem;
}

.collapse-icon.left a {
  padding-left: 28px;
}

.collapse-icon.left a:before,
.collapse-icon.left a:after {
  left: 20px;
  right: auto;
}

.collapse-icon [aria-expanded="true"]:before {
  opacity: 0;
}

.collapse-icon [aria-expanded="false"]:after {
  opacity: 0;
}

.collapse-icon.accordion-icon-rotate [aria-expanded="true"]:before {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

.collapse-icon.accordion-icon-rotate [aria-expanded="false"]:after {
  -webkit-transform: rotate(-180deg);
  -ms-transform: rotate(-180deg);
  transform: rotate(-180deg);
}

.dropdown.show .btn-outline-warning.dropdown-toggle,
.dropdown.show .btn-outline-light.dropdown-toggle {
  color: #ffffff;
}

.dropdown .btn:not(.btn-sm):not(.btn-lg),
.dropdown .btn:not(.btn-sm):not(.btn-lg).dropdown-toggle {
  padding: 0.85rem 1.5rem;
}

.dropdown .btn:not(.btn-sm):not(.btn-lg).btn-round,
.dropdown .btn:not(.btn-sm):not(.btn-lg).dropdown-toggle.btn-round {
  border-radius: 2rem;
}

.dropdown .btn.dropdown-toggle.dropdown-toggle-split {
  padding: 0.85rem 1rem;
  border-left-color: rgba(255, 255, 255, 0.2) !important;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown [class*="btn-outline-"].dropdown-toggle.dropdown-toggle-split {
  border-left: 0 !important;
}

.dropdown [class*="btn-outline-"].dropdown-toggle.dropdown-toggle-split:active {
  color: #ffffff;
}

.dropdown .dropdown-menu {
  border: 1px solid rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  -webkit-transform: scale(1, 0);
  -ms-transform: scale(1, 0);
  transform: scale(1, 0);
  box-shadow: 0 5px 25px rgba(0, 0, 0, 0.1);
  min-width: 8rem;
}

.dropdown .dropdown-menu::before {
  content: "";
  position: absolute;
  top: -1px;
  left: 1.2rem;
  width: 0.75rem;
  height: 0.75rem;
  display: block;
  background: #ffffff;
  -webkit-transform: rotate(45deg) translate(-7px);
  -ms-transform: rotate(45deg) translate(-7px);
  transform: rotate(45deg) translate(-7px);
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-left: 1px solid rgba(0, 0, 0, 0.1);
  z-index: 10;
  box-sizing: border-box;
}

.dropdown .dropdown-menu.dropdown-menu-right::before {
  right: 0.6rem;
  left: auto;
}

.dropdown .dropdown-menu .dropdown-header {
  padding: 0.714rem 1.428rem;
  margin-top: 0.714rem;
}

.dropdown .dropdown-menu .dropdown-item {
  padding: 0.5rem 1rem;
  color: #626262;
  width: auto;
  cursor: pointer;
}

.dropdown .dropdown-menu .dropdown-item:hover {
  background-color: #f8f8f8;
  color: #7367f0;
}

.dropdown .dropdown-menu .dropdown-item.active {
  color: #ffffff;
}

.dropdown .dropdown-menu .dropdown-item:active {
  background-color: transparent;
  color: #212529;
}

.dropdown i {
  margin-right: 0.5rem;
}

.dropdown.no-arrow .dropdown-toggle:after {
  display: none;
}

.dropdown.no-arrow .dropdown-toggle i {
  margin-right: 0;
}

.dropdown,
.dropup,
.dropright,
.dropleft {
  position: relative;
}

.dropdown .dropdown-menu .dropdown-item,
.dropup .dropdown-menu .dropdown-item,
.dropright .dropdown-menu .dropdown-item,
.dropleft .dropdown-menu .dropdown-item {
  padding: 0.714rem 1.428rem;
}

.show > .dropdown-menu {
  -webkit-transform: scale(1, 1);
  -ms-transform: scale(1, 1);
  transform: scale(1, 1);
  opacity: 1;
  display: block;
}

.dropdown-toggle::after {
  border: none !important;
  font-family: "feather";
  content: "\e842" !important;
  position: relative;
  top: 1px;
  right: 0;
  left: 0.714rem;
  padding: 0;
  margin: 0;
  vertical-align: 0;
}

.dropdown-toggle.dropdown-toggle-split {
  border-left: 1px solid rgba(255, 255, 255, 0.2);
}

.dropdown-toggle.dropdown-toggle-split:after {
  left: 0;
}

.dropdown-toggle.nav-hide-arrow::after {
  display: none;
}

.dropdown-toggle:focus {
  outline: 0;
}

/* .dropdown-menu-right {
left: auto !important; // Input group with dropdown issue for dropdown-menu-right
right: 0;
} */
.btn-secondary ~ .dropdown-menu .dropdown-item.active,
.btn-secondary ~ .dropdown-menu .dropdown-item:hover {
  background-color: #cccccc;
}

.dropup {
  position: relative;
}

.dropup .btn,
.dropup .btn.dropdown-toggle {
  padding: 0.85rem 1.5rem;
}

.dropup .btn.dropdown-toggle.dropdown-toggle-split {
  padding: 0.85rem 1rem;
  border-color: rgba(255, 255, 255, 0.2) !important;
}

.dropup .dropdown-toggle::after {
  content: "\e845" !important;
  vertical-align: 0.05rem;
}

.dropup .dropdown-menu::before {
  content: "";
  position: absolute;
  bottom: -0.714rem;
  left: 1.214rem;
  width: 0.714rem;
  height: 0.714rem;
  display: block;
  background: #ffffff;
  -webkit-transform: rotate(45deg) translate(-7px);
  -ms-transform: rotate(45deg) translate(-7px);
  transform: rotate(45deg) translate(-7px);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  z-index: 10;
  box-sizing: border-box;
}

.dropup .dropdown-menu.dropdown-menu-right::before {
  left: auto;
  right: 0.714rem;
}

.dropup .dropdown-submenu .dropdown-menu {
  bottom: auto;
  top: 0;
}

.dropleft .btn,
.dropleft .btn.dropdown-toggle {
  padding: 0.85rem 1.5rem;
}

.dropleft .btn.dropdown-toggle.dropdown-toggle-split {
  padding: 0.85rem 1rem;
}

/*
.dropleft .dropdown-toggle::before {
border: none !important;
font-family: "feather";
content: "\e843" !important;
}
*/

.dropleft .dropdown-toggle::before {
  border: none !important;
  font-family: "feather";
  content: "" !important;
}

.dropleft .dropdown-menu::before {
  content: "";
  position: absolute;
  top: 1.285rem;
  right: 0;
  width: 0.714rem;
  height: 0.714rem;
  display: block;
  background: #ffffff;
  -webkit-transform: rotate(130deg) translate(-8px);
  -ms-transform: rotate(130deg) translate(-8px);
  transform: rotate(130deg) translate(-8px);
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-left: 1px solid rgba(0, 0, 0, 0.1);
  z-index: 10;
  box-sizing: border-box;
}

.dropright .btn,
.dropright .btn.dropdown-toggle {
  padding: 0.85rem 1.5rem;
}

.dropright .btn.dropdown-toggle.dropdown-toggle-split {
  padding: 0.85rem 1rem;
}

.dropright .dropdown-toggle::after {
  border: none !important;
  font-family: "feather";
  content: "\e844" !important;
}

.dropright .dropdown-menu::before {
  content: "";
  position: absolute;
  top: 1.285rem;
  left: -0.9rem;
  width: 0.714rem;
  height: 0.714rem;
  display: block;
  background: #ffffff;
  -webkit-transform: rotate(140deg) translate(-9px);
  -ms-transform: rotate(140deg) translate(-9px);
  transform: rotate(140deg) translate(-9px);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  z-index: 10;
  box-sizing: border-box;
}

.dropdown.dropdown-icon-wrapper .dropdown-toggle:after,
.dropup.dropdown-icon-wrapper .dropdown-toggle:after {
  display: none;
}

.dropdown.dropdown-icon-wrapper .dropdown-menu,
.dropup.dropdown-icon-wrapper .dropdown-menu {
  min-width: auto;
}

.dropdown.dropdown-icon-wrapper .dropdown-menu .dropdown-item,
.dropup.dropdown-icon-wrapper .dropdown-menu .dropdown-item {
  padding: 0.5rem 1.1rem;
  cursor: pointer;
}

.dropdown.dropdown-icon-wrapper .dropdown-menu .dropdown-item i,
.dropup.dropdown-icon-wrapper .dropdown-menu .dropdown-item i {
  font-size: 1.3rem;
  color: #626262;
}

.dropdown.dropdown-icon-wrapper .dropdown-menu .dropdown-item:hover,
.dropup.dropdown-icon-wrapper .dropdown-menu .dropdown-item:hover {
  color: #ffffff;
}

.btn.btn-white ~ .dropdown-menu .dropdown-item:not(.acitve):hover {
  color: #626262 !important;
}

.btn.btn-white ~ .dropdown-menu .dropdown-item.active {
  color: #626262;
}

.horizontal-menu-wrapper .dropdown-menu,
.header-navbar .dropdown-menu {
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: slideIn;
  animation-name: slideIn;
}

@-webkit-keyframes slideIn {
  0% {
      -webkit-transform: translateY(1rem);
      transform: translateY(1rem);
      opacity: 0;
  }

  100% {
      -webkit-transform: translateY(0rem);
      transform: translateY(0rem);
      opacity: 1;
  }

  0% {
      -webkit-transform: translateY(1rem);
      transform: translateY(1rem);
      opacity: 0;
  }
}

@keyframes slideIn {
  0% {
      -webkit-transform: translateY(1rem);
      transform: translateY(1rem);
      opacity: 0;
  }

  100% {
      -webkit-transform: translateY(0rem);
      transform: translateY(0rem);
      opacity: 1;
  }

  0% {
      -webkit-transform: translateY(1rem);
      transform: translateY(1rem);
      opacity: 0;
  }
}

/* ===============================================================================================
  File Name: navbar.scss
  Description: Contain header navigation bar, vertical main navigation bar and
  horiznotal main navigation bar related SCSS.
  ----------------------------------------------------------------------------------------------
  Item Name: Vuexy  - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: PIXINVENT
  Author URL: http://www.themeforest.net/user/pixinvent
================================================================================================*/
.navbar-dark .hamburger-inner,
.navbar-dark .hamburger-inner::before,
.navbar-dark .hamburger-inner::after {
  background-color: #ffffff;
}

.header-navbar-shadow {
  display: none;
}

.navbar-floating .header-navbar-shadow {
  display: block;
  background: -webkit-linear-gradient(top,
          rgba(248, 248, 248, 0.95) 44%,
          rgba(248, 248, 248, 0.46) 73%,
          rgba(255, 255, 255, 0));
  background: linear-gradient(180deg,
          rgba(248, 248, 248, 0.95) 44%,
          rgba(248, 248, 248, 0.46) 73%,
          rgba(255, 255, 255, 0));
  padding-top: 2.2rem;
  background-repeat: repeat;
  width: 100%;
  height: 102px;
  position: fixed;
  top: 0;
  z-index: 11;
}

.header-navbar {
  padding: 0;
  min-height: 4.5rem;
  font-family: "Montserrat", Helvetica, Arial, serif;
  -webkit-transition: 300ms ease all;
  transition: 300ms ease all;
  background: -webkit-linear-gradient(top,
          rgba(248, 248, 248, 0.95) 44%,
          rgba(248, 248, 248, 0.46) 73%,
          rgba(255, 255, 255, 0));
  background: linear-gradient(180deg,
          rgba(248, 248, 248, 0.95) 44%,
          rgba(248, 248, 248, 0.46) 73%,
          rgba(255, 255, 255, 0));
  z-index: 997;
  /* For sm screen*/
}

.header-navbar.fixed-top {
  left: 260px;
}

.header-navbar[class*="bg-"] .navbar-nav .nav-item > a {
  color: #ffffff !important;
}

.header-navbar[class*="bg-"] .navbar-nav .nav-item > a i,
.header-navbar[class*="bg-"] .navbar-nav .nav-item > a span {
  color: #ffffff !important;
}

.header-navbar[class*="bg-"] .navbar-nav .nav-item .dropdown-menu i {
  color: inherit !important;
}

.header-navbar[class*="bg-"] .navbar-nav .search-input .input,
.header-navbar[class*="bg-"] .navbar-nav .search-input .search-list .auto-suggestion span {
  color: #626262 !important;
}

.header-navbar.floating-nav {
  margin: 1.3rem 2.2rem 0;
  border-radius: 0.5rem;
  position: fixed;
  width: calc(100vw - (100vw - 100%) - calc(2.2rem * 2) - 260px);
  z-index: 12;
  right: 0;
}

.header-navbar.navbar-static-top {
  background: transparent;
  box-shadow: none !important;
  top: 0;
  right: 0;
  left: 260px;
  width: calc(100vw - (100vw - 100%) - 260px);
}

.header-navbar .navbar-wrapper {
  width: 100%;
}

.header-navbar.navbar-border {
  border-bottom: 1px solid #e4e7ed;
}

.header-navbar.navbar-dark.navbar-border {
  border-bottom: 1px solid #8596b5;
}

.header-navbar.navbar-shadow {
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.05);
}

.header-navbar:not(.navbar-horizontal) .nav-link.dropdown-toggle::after {
  display: none;
}

.header-navbar .navbar-container ul.nav li.dropdown-language {
  padding-right: 0;
}

.header-navbar .navbar-container ul.nav li.dropdown-language .selected-language {
  font-size: 0.97rem;
  letter-spacing: -0.25px;
  font-weight: 500;
}

.header-navbar .navbar-container {
  padding-left: 1rem;
  -webkit-transition: 300ms ease all;
  transition: 300ms ease all;
  background: inherit;
  margin-left: 0;
}

.header-navbar .navbar-container .bookmark-wrapper ul.nav li > a.nav-link {
  padding: 1.4rem 0.5rem 1.35rem;
}

.header-navbar .navbar-container .bookmark-wrapper ul.nav li > a.nav-link i:hover {
  color: #7367f0;
}

.header-navbar .navbar-container .bookmark-input {
  z-index: 1;
}

.header-navbar .navbar-container ul.nav li.dropdown .dropdown-menu {
  top: 48px;
}

.header-navbar .navbar-container ul.nav li .badge {
  padding: 0.42em 0.6em 0.25rem;
}

.header-navbar .navbar-container ul.nav li .badge.badge-up {
  position: absolute;
  top: 12px;
  right: -2px;
}

.header-navbar .navbar-container ul.nav li.dropdown-language > a.nav-link {
  padding: 1.65rem 0.5rem;
}

.header-navbar .navbar-container ul.nav li > a.nav-link {
  color: #626262;
  padding: 1.6rem 0.5rem 1.35rem 1rem;
}

.header-navbar .navbar-container ul.nav li > a.nav-link i.flag-icon {
  margin-right: 0.5rem;
}

.header-navbar .navbar-container ul.nav li.dropdown-user .dropdown-menu-right {
  right: 12px;
  left: auto;
  padding: 0.5rem;
}

.header-navbar .navbar-container ul.nav li.dropdown-user .dropdown-menu-right .dropdown-item {
  padding: 10px;
}

.header-navbar .navbar-container ul.nav li.dropdown-user .dropdown-menu-right .dropdown-item:hover,
.header-navbar .navbar-container ul.nav li.dropdown-user .dropdown-menu-right .dropdown-item:active {
  background: #7367f0;
  color: #ffffff !important;
}

.header-navbar .navbar-container ul.nav li a.dropdown-user-link {
  padding: 0.7rem 1rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.header-navbar .navbar-container ul.nav li a.dropdown-user-link .user-name {
  display: inline-block;
  margin-bottom: 0.435rem;
  margin-left: 0.2rem;
}

.header-navbar .navbar-container ul.nav li a.dropdown-user-link .user-status {
  font-size: smaller;
}

.header-navbar .navbar-container ul.nav li a.dropdown-user-link img {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.12), 0 2px 4px 0 rgba(0, 0, 0, 0.08);
}

.header-navbar .navbar-container ul.nav li a.menu-toggle {
  padding: 1.34rem 0.22rem 1.35rem 0;
}

.header-navbar .navbar-container ul.nav li a.menu-toggle i {
  font-size: 1.75rem;
}

.header-navbar .navbar-container ul.nav li a.nav-link-label {
  padding: 1.6rem 0rem 1.35rem 0.8rem;
}

.header-navbar .navbar-container ul.nav li a.dropdown-user-link .user-nav {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
  float: left;
  margin-right: 0.8rem;
}

.header-navbar .navbar-container ul.nav li div.input-group {
  padding: 0.7rem 1rem;
}

.header-navbar .navbar-container ul.nav li i.ficon {
  font-size: 1.5rem;
  color: #626262;
}

.header-navbar .navbar-container ul.nav li i.ficon:hover {
  color: #7367f0;
}

.header-navbar .navbar-container ul.nav li .media-list {
  max-height: 25rem;
}

.header-navbar .navbar-container ul.nav li .scrollable-container {
  position: relative;
}

.header-navbar .navbar-container .dropdown-menu-media {
  width: 26rem;
}

.header-navbar .navbar-container .dropdown-menu-media .media-list .media {
  padding: 1rem;
  border: none;
  border-bottom: 1px solid #dae1e7;
}

.header-navbar .navbar-container .dropdown-menu-media .media-list .media:hover {
  background: #f8f8f8;
}

.header-navbar .navbar-container .dropdown-menu-media .media-list .media .media-meta {
  color: #626262;
}

.header-navbar .navbar-container .dropdown-menu-media .media-list a:last-of-type .media {
  border-bottom: none;
}

.header-navbar .navbar-container .dropdown-menu-media .dropdown-menu-header {
  border-bottom: 1px solid #dae1e7;
}

.header-navbar .navbar-container .dropdown-menu-media .dropdown-menu-footer a {
  padding: 0.3rem;
  border-top: 1px solid #dae1e7;
}

.header-navbar .navbar-container .dropdown-menu-media.dropdown-cart.empty-cart:before {
  background: #ffffff;
  border-color: rgba(0, 0, 0, 0.1);
}

.header-navbar .navbar-container .dropdown-menu-media.dropdown-cart .cart-item {
  color: #626262;
}

.header-navbar .navbar-container .dropdown-menu-media.dropdown-cart .media {
  padding: 0;
}

.header-navbar .navbar-container .dropdown-menu-media.dropdown-cart .media .media-left {
  width: 100px;
  text-align: center;
  padding-right: 0;
  margin-right: 1rem;
}

.header-navbar .navbar-container .dropdown-menu-media.dropdown-cart .media .media-left img {
  height: 100%;
}

.header-navbar .navbar-container .dropdown-menu-media.dropdown-cart .media .media-body {
  padding: 1rem;
  padding-left: 0;
  overflow: hidden;
}

.header-navbar.navbar-without-menu .navbar-container {
  margin-left: 0;
}

.header-navbar .nav-item + .nav-item {
  margin-left: 0rem;
}

@media (max-width: 767.98px) {
  .header-navbar .navbar-header {
      width: 100% !important;
      padding: 0.5rem 1rem;
      position: relative;
  }

  .header-navbar .navbar-header .menu-toggle {
      top: 2px;
      position: relative;
  }

  .header-navbar .navbar-header .open-navbar-container i {
      font-size: 1.8rem;
  }

  .header-navbar .navbar-header .navbar-brand {
      position: absolute;
      left: 50%;
      top: 0;
      -webkit-transform: translate(-50%, 0);
      -ms-transform: translate(-50%, 0);
      transform: translate(-50%, 0);
  }

  .header-navbar.navbar-with-menu .navbar-container {
      width: 100%;
      display: table;
      margin: 0;
      height: auto;
  }
}

/* Modern menu For md screen*/
@media (max-width: 991.98px) {
  [data-menu="vertical-menu-modern"] .header-navbar .navbar-header {
      width: 100% !important;
      padding: 0.5rem 1rem;
      position: relative;
  }

  [data-menu="vertical-menu-modern"] .header-navbar .navbar-header .menu-toggle {
      top: 2px;
      position: relative;
  }

  [data-menu="vertical-menu-modern"] .header-navbar .navbar-header .open-navbar-container i {
      font-size: 1.8rem;
  }

  [data-menu="vertical-menu-modern"] .header-navbar .navbar-header .navbar-brand {
      position: absolute;
      left: 50%;
      top: 0;
      -webkit-transform: translate(-50%, 0);
      -ms-transform: translate(-50%, 0);
      transform: translate(-50%, 0);
  }

  [data-menu="vertical-menu-modern"] .header-navbar.navbar-with-menu .navbar-container {
      width: 100%;
      display: table;
      margin: 0;
      height: auto;
  }

  [data-menu="vertical-menu-modern"] .navbar-dark .navbar-header .navbar-nav .nav-link,
  [data-menu="vertical-menu-modern"] .navbar-semi-dark .navbar-header .navbar-nav .nav-link {
      color: #ffffff;
  }

  [data-menu="vertical-menu-modern"] .navbar-dark .navbar-container .navbar-nav .nav-link,
  [data-menu="vertical-menu-modern"] .navbar-semi-dark .navbar-container .navbar-nav .nav-link {
      color: #b8c2cc;
  }

  [data-menu="vertical-menu-modern"] .navbar-light .navbar-header .navbar-nav .nav-link {
      color: #b8c2cc;
  }
}

.navbar-light {
  background: #ffffff;
}

.navbar-light.navbar-horizontal {
  background: #ffffff;
}

.navbar-light .navbar-nav li {
  line-height: 1;
}

.navbar-light .navbar-nav .active.nav-link {
  background-color: rgba(0, 0, 0, 0.03);
}

.navbar-light .navbar-nav .disabled.nav-link {
  color: #6c757d;
}

.navbar-dark {
  background: #b8c2cc;
}

.navbar-dark .navbar-brand {
  color: #ffffff !important;
}

.navbar-dark.navbar-horizontal {
  background: #b8c2cc;
}

.navbar-dark .nav-search .form-control,
.navbar-dark .nav-search .btn-secondary {
  color: #ffffff;
  background: #b8c2cc;
}

.navbar-dark .navbar-nav li {
  line-height: 1;
}

.navbar-dark .navbar-nav .active.nav-link {
  background-color: rgba(255, 255, 255, 0.05);
}

.navbar-dark .navbar-nav .disabled.nav-link {
  color: #dae1e7;
}

.navbar-semi-dark {
  background: #ffffff;
}

.navbar-semi-dark .navbar-header {
  background: #b8c2cc;
}

.navbar-semi-dark .navbar-header .brand-text {
  color: #ffffff;
}

.navbar-semi-dark .navbar-nav li {
  line-height: 1;
}

.navbar-semi-dark .navbar-nav .nav-link {
  color: #b8c2cc;
}

.navbar-semi-dark .navbar-nav .active.nav-link {
  background-color: rgba(0, 0, 0, 0.03);
}

.navbar-semi-dark .navbar-nav .disabled.nav-link {
  color: #6c757d;
}

.navbar-semi-light {
  background: #b8c2cc;
}

.navbar-semi-light .navbar-header {
  background: #ffffff;
}

.navbar-semi-light .navbar-header .brand-text {
  color: #b8c2cc;
}

.navbar-semi-light .navbar-nav li {
  line-height: 1;
}

.navbar-semi-light .navbar-nav .nav-link {
  color: #ffffff;
}

.navbar-semi-light .navbar-nav .active.nav-link {
  background-color: rgba(0, 0, 0, 0.03);
}

.navbar-semi-light .navbar-nav .disabled.nav-link {
  color: #6c757d;
}

@-moz-document url-prefix() {
  ul li a .children-in {
      position: relative;
      right: 3px;
      top: -14px;
  }
}

.navbar-menu-icon span {
  display: block;
  position: absolute;
  height: 2px;
  width: 100%;
  background: #b8c2cc;
  border-radius: 9px;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
}

.navbar-menu-icon span:nth-child(1) {
  top: 0;
}

.navbar-menu-icon span:nth-child(2) {
  top: 10px;
}

.navbar-menu-icon span:nth-child(3) {
  top: 20px;
}

.navbar-menu-icon.show span:nth-child(1) {
  top: 10px;
  -webkit-transform: rotate(135deg);
  -ms-transform: rotate(135deg);
  transform: rotate(135deg);
}

.navbar-menu-icon.show span:nth-child(2) {
  opacity: 0;
  left: -60px;
}

.navbar-menu-icon.show span:nth-child(3) {
  top: 10px;
  -webkit-transform: rotate(-135deg);
  -ms-transform: rotate(-135deg);
  transform: rotate(-135deg);
}

@media (max-width: 767.98px) {
  .header-navbar .navbar-nav .show {
      position: static;
  }

  .header-navbar .navbar-nav .open-navbar-container {
      padding-top: 0.625rem;
  }

  .header-navbar .navbar-container .show .dropdown-menu {
      right: 0;
      left: 0 !important;
      float: none;
      width: auto;
      margin-top: 0;
      overflow: hidden;
  }

  .header-navbar .navbar-container .dropdown-user .dropdown-menu-right {
      right: 0 !important;
  }

  .header-navbar .navbar-container ul.nav li.nav-item i {
      margin-right: 0.2rem;
  }

  .header-navbar .navbar-container ul.nav li a.dropdown-user-link {
      padding: 0.8rem 0.6rem;
  }

  .header-navbar .navbar-container ul.nav li .dropdown-toggle::after {
      margin-right: 0;
      margin-left: -2px;
  }

  .navbar-dark .hamburger-inner,
  .navbar-dark .hamburger-inner::before,
  .navbar-dark .hamburger-inner::after,
  .navbar-semi-dark .hamburger-inner,
  .navbar-semi-dark .hamburger-inner::before,
  .navbar-semi-dark .hamburger-inner::after {
      background-color: #ffffff;
  }

  .navbar-dark .navbar-header .navbar-nav .nav-link,
  .navbar-semi-dark .navbar-header .navbar-nav .nav-link {
      color: #ffffff;
  }

  .navbar-dark .navbar-container .navbar-nav .nav-link,
  .navbar-semi-dark .navbar-container .navbar-nav .nav-link {
      color: #b8c2cc;
  }

  .navbar-light .navbar-header .navbar-nav .nav-link,
  .navbar-semi-light .navbar-header .navbar-nav .nav-link {
      color: #b8c2cc;
  }

  .navbar-light .navbar-container .navbar-nav .nav-link,
  .navbar-semi-light .navbar-container .navbar-nav .nav-link {
      color: #b8c2cc;
  }
}

.headroom {
  will-change: transform;
  -webkit-transition: -webkit-transform 200ms linear;
  transition: -webkit-transform 200ms linear;
  transition: transform 200ms linear;
  transition: transform 200ms linear, -webkit-transform 200ms linear;
}

.headroom--pinned-top {
  -webkit-transform: translateY(0%);
  -ms-transform: translateY(0%);
  transform: translateY(0%);
}

.headroom--unpinned-top {
  -webkit-transform: translateY(-100%);
  -ms-transform: translateY(-100%);
  transform: translateY(-100%);
}

.headroom--pinned-bottom {
  -webkit-transform: translateY(0%);
  -ms-transform: translateY(0%);
  transform: translateY(0%);
}

.headroom--unpinned-bottom {
  -webkit-transform: translateY(100%);
  -ms-transform: translateY(100%);
  transform: translateY(100%);
}

@media (max-width: 575.98px) {
  .header-navbar .navbar-container ul.nav li .selected-language {
      display: none;
  }
}

body[data-col="1-column"] .header-navbar.floating-nav {
  width: calc(100vw - (100vw - 100%) - calc(2.2rem * 2));
}

@media (min-width: 1200px) and (-ms-high-contrast: active),
(-ms-high-contrast: none) {
  body:not([data-col="1-columns"]) .header-navbar.floating-nav {
      width: calc(100vw - (100vw - 100%) - 60px - 260px);
  }
}

@media (max-width: 1199.98px) {
  .header-navbar .navbar-container ul.nav li a.dropdown-user-link {
      padding: 0.7rem 1.23rem;
  }

  .header-navbar .navbar-container ul.nav li .dropdown-toggle::after {
      margin-right: 0;
      margin-left: -2px;
  }

  .header-navbar.navbar-static-top {
      left: 0;
      width: 100%;
  }
}

@media (max-width: 767px) {
  #navbar-mobile.navbar-collapse .navbar-nav {
      margin: 0;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -webkit-flex-flow: row wrap;
      -ms-flex-flow: row wrap;
      flex-flow: row wrap;
  }

  #navbar-mobile.navbar-collapse .navbar-nav .dropdown-menu {
      position: absolute;
  }

  #navbar-mobile.navbar-collapse .navbar-nav .nav-item {
      float: left;
  }
}

@media (max-width: 991.98px) {
  #navbar-mobile.navbar-collapse .navbar-nav {
      margin: 0;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -webkit-flex-flow: row wrap;
      -ms-flex-flow: row wrap;
      flex-flow: row wrap;
  }

  #navbar-mobile.navbar-collapse .navbar-nav .dropdown-menu {
      position: absolute;
  }

  #navbar-mobile.navbar-collapse .navbar-nav .nav-item {
      float: left;
  }
}

/* ----------- iPhone 5, 5S  iPhone 6----------- */
/* Landscape */
@media only screen and (min-device-width: 26.78em) and (max-device-width: 47.64em) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
  .header-navbar .navbar-container .show .dropdown-menu {
      max-height: 180px;
  }
}

/* ----------- iPhone 6+ ----------- */
/* Landscape */
@media only screen and (min-device-width: 29.57em) and (max-device-width: 52.57em) and (-webkit-min-device-pixel-ratio: 3) and (orientation: landscape) {
  .header-navbar .navbar-container .show .dropdown-menu {
      max-height: 280px;
  }
}

/*----------------------------- For Navbar Component -------------------------*/
#navbar-component .navbar {
  z-index: auto !important;
}

#navbar-component .navbar.floating-nav {
  border-radius: 0.5rem;
}

#navbar-component .navbar .navbar-nav .nav-item a {
  color: inherit;
}

#navbar-component .navbar.header-navbar.navbar-dark .navbar-nav .nav-link {
  color: #ffffff;
}

#navbar-component .navbar.header-navbar.navbar-dark .navbar-nav .nav-link i {
  color: #ffffff;
}

#navbar-component .navbar .navbar-nav .dropdown-language.show,
#navbar-component .navbar .navbar-nav .dropdown-notification.show,
#navbar-component .navbar .navbar-nav .dropdown-user.show {
  position: relative;
}

#navbar-component .navbar .navbar-nav .dropdown-language .dropdown-menu.show,
#navbar-component .navbar .navbar-nav .dropdown-notification .dropdown-menu.show,
#navbar-component .navbar .navbar-nav .dropdown-user .dropdown-menu.show {
  position: absolute;
  right: 0;
  left: auto !important;
}

#navbar-component .navbar.navbar-dark .navbar-toggler,
#navbar-component .navbar.navbar-light .navbar-toggler {
  font-size: 1.2rem;
}

#navbar-component .navbar.navbar-dark .navbar-toggler:focus,
#navbar-component .navbar.navbar-light .navbar-toggler:focus {
  outline: 0;
}

#navbar-component #navbar-collapsed .navbar {
  border-radius: 0;
}

@media (min-width: 992px) {
  #navbar-component .navbar .navbar-container {
      background: inherit;
  }

  #navbar-component .nav .dropdown-language {
      float: right;
  }
}

@media (max-width: 992px) {
  #navbar-component .navbar .navbar-container {
      background: inherit;
  }

  #navbar-component .navbar .navbar-container .navbar-nav {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -webkit-flex-direction: row;
      -ms-flex-direction: row;
      flex-direction: row;
  }
}

@media (max-width: 1201px) {
  .header-navbar.fixed-top {
      left: 0;
  }

  .horizontal-layout .header-navbar .navbar-container {
      padding-left: 1.5rem;
      padding-right: 1.2rem;
  }
}

@media (max-width: 1199px) {
  .header-navbar .navbar-container ul.nav li a.dropdown-user-link .user-name {
      margin-bottom: 0;
  }
}

@media (max-width: 768px) {
  #navbar-component .navbar .navbar-nav .dropdown-notification.show {
      position: static;
  }
}

@media (max-width: 575.98px) {
  .header-navbar.floating-nav {
      width: calc(100vw - (100vw - 100%) - 2.4rem) !important;
      margin-left: 1.2rem;
      margin-right: 1.2rem;
  }

  .content.app-content .content-area-wrapper {
      margin-left: 1.2rem !important;
      margin-right: 1.2rem !important;
  }

  .header-navbar .navbar-container {
      padding-left: 1rem !important;
      padding-right: 0 !important;
  }
}

@media (max-width: 992px) {
  .navbar-container #navbar-mobile .nav-back {
      margin-top: 5px;
  }
}

.card-group,
.card-deck {
  margin-bottom: 0.75rem;
}

.card {
  margin-bottom: 2.2rem;
  border: none;
  border-radius: 0.5rem;
  box-shadow: 0 4px 25px 0 rgba(0, 0, 0, 0.1);
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.card .card {
  box-shadow: none !important;
}

.card .card-subtitle {
  margin-top: 0.5rem;
}

.card .card-title {
  font-weight: 500;
  letter-spacing: 0.05rem;
  font-size: 1.32rem;
  margin-bottom: 0.5rem;
}

.card .card-bordered {
  border: 1px solid rgba(0, 0, 0, 0.125);
}

.card .card-img {
  -o-object-fit: cover;
  object-fit: cover;
}

.card .card-img-overlay {
  border-radius: 0.25rem;
}

.card.card-fullscreen {
  display: block;
  z-index: 9999;
  position: fixed;
  width: 100% !important;
  height: 100% !important;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  overflow: auto;
}

.card .card-body[class*="border-bottom-"] {
  border-bottom-width: 2px !important;
}

.card .card-img-overlay.bg-overlay {
  background: rgba(0, 0, 0, 0.45);
}

.card .card-img-overlay .text-muted {
  color: #1e1e1e !important;
}

.card.card-minimal {
  border: none;
  box-shadow: none;
}

.card .card-header {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border-bottom: none;
  padding: 1.5rem 1.5rem 0;
  background-color: transparent;
}

.card .card-header .card-title {
  margin-bottom: 0;
}

.card .card-header .heading-elements,
.card .card-header .heading-elements-toggle {
  background-color: inherit;
  position: absolute;
  top: 17px;
  right: 21px;
  cursor: pointer;
}

.card .card-header .heading-elements.heading-top-elements .page-item,
.card .card-header .heading-elements-toggle.heading-top-elements .page-item {
  display: inline-block;
}

.card .card-header .heading-elements a,
.card .card-header .heading-elements-toggle a {
  padding: 0;
  padding-left: 8px;
}

.card .card-header .heading-elements a.btn,
.card .card-header .heading-elements-toggle a.btn {
  padding-top: 6px;
  padding-bottom: 6px;
}

.card .card-header .heading-elements a[data-action="collapse"] i,
.card .card-header .heading-elements-toggle a[data-action="collapse"] i {
  -webkit-transition: all 0.25s ease-out;
  transition: all 0.25s ease-out;
  display: inline-block;
}

.card .card-header .heading-elements a[data-action="collapse"].rotate i,
.card .card-header .heading-elements-toggle a[data-action="collapse"].rotate i {
  -webkit-transform: rotate(-180deg);
  -ms-transform: rotate(-180deg);
  transform: rotate(-180deg);
}

.card .card-footer {
  border-top: 1px solid #dae1e7;
}

.card-columns .card {
  margin-bottom: 2.2rem;
}

.text-white .card-text {
  color: white;
}

.text-white .card-img-overlay .text-muted {
  color: #ffffff !important;
}

.text-white code {
  background-color: white;
}

.text-white .heading-elements i {
  color: #ffffff;
}

.card-head-inverse {
  color: #ffffff;
}

.card-head-inverse .heading-elements i {
  color: #ffffff;
}

.card-transparent {
  background-color: transparent;
}

@media (max-width: 991.98px) {
  .heading-elements {
      text-align: center;
  }

  .heading-elements .list-inline {
      display: none;
  }

  .heading-elements.visible {
      top: 22px !important;
      display: block;
      margin-top: 0;
      top: 100%;
      top: 50px;
      height: auto;
      left: 0;
      z-index: 997;
      position: absolute;
      width: 100%;
  }

  .heading-elements.visible .list-inline {
      background-color: #ffffff;
      display: block;
      position: absolute;
      top: 12px;
      right: 15px;
  }
}

@media (min-width: 992px) {
  .heading-elements-toggle {
      display: none;
  }
}

.overlay-img-card .card-img-overlay,
.overlay-img-card img {
  max-height: 34.64rem;
}

.activity-timeline.timeline-left {
  border-left: 2px solid #dae1e7;
  padding-left: 40px;
  margin-left: 1.5rem;
}

.activity-timeline.timeline-left li {
  position: relative;
  margin-bottom: 20px;
}

.activity-timeline.timeline-left li p {
  margin-bottom: 0;
}

.activity-timeline.timeline-left li .timeline-icon {
  position: absolute;
  top: 0;
  left: -4.3rem;
  padding: 0.7rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 50%;
  color: #ffffff;
}

.activity-timeline.timeline-left li .timeline-icon i {
  vertical-align: sub;
}

.activity-timeline.timeline-right {
  border-right: 2px solid #dae1e7;
  padding-right: 40px;
  margin-right: 1.5rem;
}

.activity-timeline.timeline-right li {
  position: relative;
  margin-bottom: 20px;
  text-align: right;
}

.activity-timeline.timeline-right li p {
  margin-bottom: 0;
}

.activity-timeline.timeline-right li .timeline-icon {
  position: absolute;
  top: 0;
  right: -4.3rem;
  padding: 0.6rem 0.7rem;
  border-radius: 50%;
  color: #ffffff;
}

.activity-timeline.timeline-right li .timeline-icon i {
  vertical-align: sub;
}

@media screen and (-ms-high-contrast: active),
(-ms-high-contrast: none) {

  .card-body,
  .card-content {
      min-height: 1px;
  }

  .profile-card-2 .card .card-header {
      display: block;
  }

  .profile-card-1 .card .avatar,
  .profile-card-3 .card .avatar {
      margin-left: auto;
      margin-right: auto;
  }
}

.breadcrumb {
  font-size: 1rem;
  font-family: "Montserrat", Helvetica, Arial, serif;
  background-color: transparent;
  padding: 0.5rem 0 0.5rem 1rem !important;
  border-left: 1px solid #d6dce1;
  border-radius: 0;
  /* .breadcrumb-item:first-child{
      a{
          &:after{
              content: "\e965";
              font-family: 'feather';
          }
      }
  } */
}

.breadcrumb > li + li::before {
  padding-right: 0.6rem;
  padding-left: 0.6rem;
}

.breadcrumb .breadcrumb-item + .breadcrumb-item {
  padding-left: 0;
}

.breadcrumb .breadcrumb-item + .breadcrumb-item:before {
  content: "\e847";
  font-family: "feather";
  color: #626262;
}

.breadcrumb .breadcrumb-item.active {
  color: #626262;
}

/* Custom scss for page headers style */
.breadcrumbs-right .breadcrumb {
  float: right;
}

.breadcrumbs-top .breadcrumb {
  margin: 0;
  padding: 0;
}

/* Component Specific */
#component-breadcrumbs .breadcrumb {
  border: none;
}

#component-breadcrumbs .breadcrumb .breadcrumb-item + .breadcrumb-item:before {
  font-family: none;
  padding: 0 1rem;
}

#component-breadcrumbs .default-breadcrumb.breadcrumb .breadcrumb-item + .breadcrumb-item:before {
  content: "/";
}

#component-breadcrumbs .breadcrumb-dots.breadcrumb .breadcrumb-item + .breadcrumb-item:before {
  content: ".";
  position: relative;
  top: -4px;
}

#component-breadcrumbs .breadcrumb-dashes.breadcrumb .breadcrumb-item + .breadcrumb-item:before {
  content: "-";
}

#component-breadcrumbs .breadcrumb-pipes.breadcrumb .breadcrumb-item + .breadcrumb-item:before {
  content: "|";
}

#component-breadcrumbs .breadcrumb-chevron.breadcrumb .breadcrumb-item + .breadcrumb-item:before {
  content: "\e844";
  font-family: "feather";
  position: relative;
  top: 1px;
}

#breadcrumb-alignment .breadcrumb-wrapper {
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.1);
  padding: 1px;
  border-radius: 10px;
}

#breadcrumb-alignment .breadcrumb-wrapper .breadcrumb {
  margin-top: 0.8rem;
}

.badge {
  color: #ffffff;
  background-color: #7367f0;
  font-weight: 400;
}

.badge[class*="badge-"] [class*="icon-"] {
  line-height: 1;
}

.badge[class*="badge-"] a {
  color: #ffffff;
}

.badge[class*="badge-"] i {
  vertical-align: middle;
}

.badge[class*="badge-"] .dropdown-toggle span,
.badge[class*="badge-"] .dropdown-toggle i,
.badge[class*="badge-"].dropdown-toggle span,
.badge[class*="badge-"].dropdown-toggle i {
  vertical-align: text-top;
}

.badge[class*="badge-"] .dropdown-toggle i,
.badge[class*="badge-"].dropdown-toggle i {
  padding-left: 0.2rem;
}

.badge[class*="badge-"] .dropdown-toggle::after,
.badge[class*="badge-"].dropdown-toggle::after {
  position: relative;
  top: 0;
  left: 0;
  font-size: 1rem;
}

.badge[class*="badge-"] .dropdown-menu a {
  color: #212529;
}

.badge.badge-square {
  border-radius: 0;
}

.badge.badge-up {
  position: absolute;
  top: -1rem;
  right: -1rem;
}

.badge.badge-up.badge-sm {
  top: -0.5rem;
  right: -0.5rem;
}

.badge-xl {
  font-size: 1.8rem;
}

.badge-lg {
  font-size: 1.2rem;
}

.badge-md {
  font-size: 1rem;
}

.badge-sm {
  font-size: 0.7rem;
}

.badge-icon i {
  font-size: 100%;
  margin-right: 5px;
}

.dropup .badge {
  cursor: pointer;
}

.nav {
  border-radius: 0.25rem;
}

.nav.wrap-border {
  border: 1px solid #dddddd;
}

.nav.wrap-border li.nav-header {
  margin: 0 0.5rem;
}

.nav.wrap-border li.nav-item,
.nav.wrap-border div {
  padding: 2px 0.714rem;
}

.nav.square-border {
  border-radius: 0;
}

.nav.square-border .nav-item .nav-link.active {
  border-radius: 0;
}

.nav .modern-nav-toggle {
  padding: 1.571rem 0;
}

.nav.nav-pills {
  margin-bottom: 1rem;
}

.nav.nav-pills .nav-item .nav-link {
  border-radius: 0.357rem;
  padding: 0.2rem 1rem;
  padding-top: 0.3rem;
  font-size: 0.98rem;
  margin-right: 0.5rem;
  color: #000000;
}

.nav.nav-pills .nav-item .nav-link.active {
  color: #ffffff;
}

.nav.nav-pills .nav-item .nav-link.disabled {
  color: #b8c2cc;
}

.nav.nav-pills .nav-item .nav-link.dropdown-toggle::after {
  top: 1px;
  left: 1px;
}

.nav.nav-pills .nav-item.dropdown.show .nav-link {
  color: #ffffff;
}

.nav.nav-pills .nav-item.dropdown.show .dropdown-item.active:hover {
  color: #7367f0;
}

.nav.nav-pills.nav-justified {
  width: 100%;
}

.nav.nav-pills.nav-justified .nav-item {
  float: none;
}

.nav.nav-pills.nav-justified .nav-link {
  text-align: center;
  margin-bottom: 5px;
}

.nav.nav-pills.nav-justified > .dropdown .dropdown-menu {
  top: auto;
  left: auto;
}

@media (min-width: 576px) {
  .nav.nav-pills.nav-justified .nav-item {
      display: block;
      width: 1%;
  }

  .nav.nav-pills.nav-justified .nav-link {
      margin-bottom: 0;
  }
}

.nav.nav-pills.nav-justified .nav-link {
  margin-right: 0;
  border-radius: 0.5rem;
}

@media (min-width: 576px) {

  .nav.nav-pills.nav-justified .nav-link.active,
  .nav.nav-pills.nav-justified .nav-link.active:hover,
  .nav.nav-pills.nav-justified .nav-link.active:focus {
      border-bottom-color: transparent;
  }
}

.nav.nav-pills.nav-justified .nav-link {
  display: block;
}

.nav.nav-pills.nav-justified .nav-link.active {
  border: none;
}

.nav.nav-pills.nav-justified .nav-link.active:hover,
.nav.nav-pills.nav-justified .nav-link.active:focus {
  border: none;
}

.nav.nav-pills.nav-active-bordered-pill .nav-item a.nav-link.active {
  border: 1px solid #7367f0;
  border-radius: 0.25rem;
  color: #7367f0;
  background-color: #ffffff;
}

.nav.nav-tabs {
  border: none;
  margin-bottom: 1rem;
  position: relative;
  border-radius: 0;
}

.nav.nav-tabs .nav-item {
  position: relative;
}

.nav.nav-tabs .nav-item .nav-link {
  color: #626262;
  font-size: 0.95rem;
  border: none;
  min-width: auto;
  font-weight: 450;
  padding: 0.61rem 0.635rem;
  border-radius: 0;
}

.nav.nav-tabs .nav-item .nav-link.active {
  border: none;
  position: relative;
  color: #7367f0;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  background-color: transparent;
}

.nav.nav-tabs .nav-item .nav-link.active:after {
  content: attr(data-before);
  height: 2px;
  width: 100%;
  left: 0;
  position: absolute;
  bottom: 0;
  top: 100%;
  background: -webkit-linear-gradient(60deg,
          #7367f0,
          rgba(115, 103, 240, 0.5)) !important;
  background: linear-gradient(30deg,
          #7367f0,
          rgba(115, 103, 240, 0.5)) !important;
  box-shadow: 0 0 8px 0 rgba(115, 103, 240, 0.5) !important;
  -webkit-transform: translateY(0px);
  -ms-transform: translateY(0px);
  transform: translateY(0px);
  -webkit-transition: all 0.2s linear;
  transition: all 0.2s linear;
}

.nav.nav-tabs .nav-item .nav-link.dropdown-toggle::after {
  top: 1px;
  left: 1px;
}

.nav.nav-tabs .nav-item .nav-link.disabled {
  color: #adb5bd;
}

.nav.nav-tabs.nav-justified {
  width: 100%;
}

.nav.nav-tabs.nav-justified .nav-item {
  float: none;
}

.nav.nav-tabs.nav-justified .nav-link {
  text-align: center;
  margin-bottom: 5px;
}

.nav.nav-tabs.nav-justified > .dropdown .dropdown-menu {
  top: auto;
  left: auto;
}

@media (min-width: 576px) {
  .nav.nav-tabs.nav-justified .nav-item {
      display: block;
      width: 1%;
  }

  .nav.nav-tabs.nav-justified .nav-link {
      margin-bottom: 0;
  }
}

.nav.nav-tabs.nav-justified .nav-link {
  margin-right: 0;
  border-radius: 0.5rem;
}

@media (min-width: 576px) {

  .nav.nav-tabs.nav-justified .nav-link.active,
  .nav.nav-tabs.nav-justified .nav-link.active:hover,
  .nav.nav-tabs.nav-justified .nav-link.active:focus {
      border-bottom-color: transparent;
  }
}

.nav.nav-tabs.nav-justified .nav-item a.nav-link {
  display: block;
  border-radius: 0;
}

.nav.nav-tabs.nav-justified .nav-item a.nav-link.active {
  border: none;
  background-color: #ffffff;
}

.nav.nav-tabs.nav-justified .nav-item a.nav-link:hover {
  border-color: transparent;
}

.nav-vertical {
  overflow: hidden;
}

.nav-vertical .nav.nav-tabs .nav-item .nav-link {
  border: none;
  margin-bottom: 0;
}

.nav-vertical .nav.nav-tabs .nav-item .nav-link.active {
  border: none;
  border-radius: 0;
}

.nav-vertical .nav.nav-tabs .nav-item .nav-link.active:after {
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
  left: auto;
  right: -1.5rem;
  bottom: 100%;
  top: 1.25rem;
  width: 2.14rem;
}

.nav-vertical .nav.nav-tabs .nav-item .nav-link:hover {
  border-right: none;
}

.nav-vertical .nav.nav-tabs.nav-left {
  float: left;
  display: table;
  margin-right: 1rem;
}

.nav-vertical .nav.nav-tabs.nav-left ~ .tab-content .tab-pane {
  display: none;
  background-color: #ffffff;
  overflow-y: auto;
}

.nav-vertical .nav.nav-tabs.nav-left ~ .tab-content .tab-pane.active {
  display: block;
}

.nav-vertical .nav.nav-tabs.nav-right {
  float: right;
  display: table;
  margin-left: 1rem;
}

.nav-vertical .nav.nav-tabs.nav-right .nav-item .nav-link.active:after {
  left: -1.5rem;
}

.nav-vertical .nav.nav-tabs.nav-right ~ .tab-content .tab-pane {
  display: none;
  background-color: #ffffff;
  overflow-y: auto;
  padding-right: 1rem;
}

.nav-vertical .nav.nav-tabs.nav-right ~ .tab-content .tab-pane.active {
  display: block;
}

@media (max-width: 576px) {
  .nav-vertical .nav.nav-tabs .nav-item .nav-link.active:after {
      height: 0;
  }
}

.alert {
  font-weight: 500;
  border: none;
}

.alert .close:focus {
  outline: 0;
}

.alert p {
  font-weight: 500;
  padding: 2px 0;
  margin-bottom: 0;
  vertical-align: middle;
}

.alert span {
  vertical-align: middle;
}

.alert .alert-heading {
  font-weight: 700;
  font-size: 0.9rem;
  padding: 0;
  padding-bottom: 8px;
}

.alert.alert-dark .alert-heading {
  box-shadow: rgba(52, 58, 64, 0.4) 0 6px 15px -7px;
}

.alert.alert-light .alert-heading {
  box-shadow: rgba(248, 249, 250, 0.4) 0 6px 15px -7px;
}

.media-list .media {
  padding: 1.25rem;
  width: 100%;
  margin-top: 0;
}

.media-list .media .media-left {
  padding-right: 1rem;
}

.media-list .media .media-right {
  padding-left: 1rem;
}

.media-list a.media {
  color: #b8c2cc !important;
}

.media-bordered .media {
  border-top: 1px solid #e4e7ed;
}

.media-bordered .media:first-child {
  border-top: 0;
}

/*=========================================================================================
File Name: progress.scss
Description: Extended bootstrap progress bar scss.
----------------------------------------------------------------------------------------
Item Name: Vuexy  - Vuejs, HTML & Laravel Admin Dashboard Template
Author: PIXINVENT
Author URL: http://www.themeforest.net/user/pixinvent
----------------------------------------------------------------------------------------

Modifiers:       `progress-lg`
                 `progress-md`
                 `progress-sm`
                 `progress-xs`

==========================================================================================*/
.progress {
  margin-bottom: 3rem;
}

.progress:last-child {
  margin-bottom: 1rem;
}

.progress.progress-xl {
  height: 1.14rem;
}

.progress.progress-lg {
  height: 0.857rem;
}

.progress.progress-md {
  height: 0.57rem;
}

.progress.progress-sm {
  height: 0.143rem;
}

.progress .progress-bar {
  border-radius: 1.28rem;
}

/* ===============================================================================================
File Name: list-group.scss
Description: Contain list item, list group related extended SCSS.
----------------------------------------------------------------------------------------------
Item Name: Vuexy  - Vuejs, HTML & Laravel Admin Dashboard Template
Author: PIXINVENT
Author URL: http://www.themeforest.net/user/pixinvent
================================================================================================*/
ul.list-inline li {
  display: inline-block;
}

ul.list-inline.list-inline-pipe > li + li:before {
  content: " | ";
  padding-right: 2px;
}

.list-group .list-group-item {
  line-height: 1.5;
}

.list-group .list-group-item.active:hover {
  background-color: #7367f0;
}

.list-group .list-group-item.active:focus {
  outline: 0;
}

.list-group .list-group-item.active:focus:hover {
  color: #ffffff;
}

.list-group .list-group-item:hover {
  background-color: #f8f8f8;
}

.list-group .list-group-item i {
  font-size: 1.2rem;
  font-weight: 100;
  position: relative;
}

.list-group .list-group-item:not(.active):focus:active {
  color: inherit;
}

.list-group .list-group-item-action:focus {
  outline: 0;
}

.list-group-item.gu-mirror {
  border-radius: 0;
}

.users-list li + li {
  margin-left: -0.785rem;
}

.users-list li img {
  border: 2px solid #ffffff;
  box-shadow: 0 2px 10px 0 rgba(184, 194, 204, 0.3);
}

.users-list li .badge {
  color: #22292f;
}

.toast-bs-container {
  z-index: 1040;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
}

.toast-bs-container .toast-position {
  position: relative;
  top: 0;
  right: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-wrap: wrap-reverse;
  -ms-flex-wrap: wrap-reverse;
  flex-wrap: wrap-reverse;
}

.toast-bs-container .toast-position .toast.show {
  opacity: 1;
  z-index: 1030;
}

.toast-placement {
  position: static;
  top: 0;
}

.toast-placement .toast {
  position: fixed;
  top: 0;
  z-index: 1030;
}

.toast {
  display: none;
  background-color: white;
  margin-top: 0.75rem;
}

.toast .toast-header .close:focus,
.toast .toast-header .close:active {
  outline: none;
}

.toast.show.toast-wrapper {
  position: static;
}

.toast.toast-translucent {
  opacity: 1;
  display: block;
}

.toast-header img {
  width: 35px;
  height: 24px;
}

@media screen and (-ms-high-contrast: active),
(-ms-high-contrast: none) {
  .toast-bs-container {
      position: fixed !important;
      left: 74% !important;
  }
}

_:-ms-lang(x),
.toast-bs-container {
  position: fixed !important;
  left: 74% !important;
}

.accordion .collapse-title,
.collapse-bordered .collapse-title {
  font-weight: 400;
  font-size: 1.2rem;
}

.accordion .card-header,
.collapse-bordered .card-header {
  cursor: pointer;
}

.collapse-title {
  color: inherit;
}

.collapse-title:hover {
  color: inherit;
}

.collapse-bordered .card:first-child {
  border-top: 0;
}

.collapse-bordered .card:last-child {
  border-bottom: 0;
}

.collapse-bordered .card {
  margin-bottom: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.04);
  border-radius: 0;
}

.collapse-bordered .card .card-header {
  padding: 1rem;
}

.collapse-bordered .card .card-body {
  padding: 1rem;
  line-height: 1.5;
}

.accordion .collapse-border-item.card {
  border: 1px solid rgba(0, 0, 0, 0.1);
  margin-bottom: 0;
}

.accordion .collapse-border-item.card:first-child {
  border-bottom: 0;
}

.accordion .collapse-border-item.card .card-header {
  padding: 1rem;
}

.accordion .collapse-border-item.card .card-body {
  padding: 1rem;
  line-height: 1.5;
}

.accordion-shadow {
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1);
  padding: 0.15rem 0.75rem;
  border-radius: 0.5rem;
}

.accordion-shadow .card.open {
  border-radius: 0.571rem;
  margin: 10px 0;
  border: 0;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.1) !important;
}

.accordion-shadow .card:first-child {
  border-top-left-radius: 0.571rem;
  border-top-right-radius: 0.571rem;
}

.accordion-shadow .card:last-child {
  border-bottom-left-radius: 0.571rem;
  border-bottom-right-radius: 0.571rem;
}

.collapse-border {
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 0.5rem;
}

.collapse-border + .collapse.show {
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.collapse-margin {
  margin-top: 0.71rem;
  margin-bottom: 0.71rem !important;
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  border-bottom: 0 solid transparent !important;
}

.collapse-margin .card-header {
  padding: 1rem;
  border-radius: 5px;
}

.collapse-margin .card-body {
  padding: 1rem;
  line-height: 1.5rem;
}

.pagination .page-item {
  border-radius: 5rem;
}

.pagination .page-item.prev-item .page-link,
.pagination .page-item.next-item .page-link,
.pagination .page-item.first .page-link,
.pagination .page-item.last .page-link {
  color: #7367f0;
}

.pagination .page-item.prev-item.disabled .page-link,
.pagination .page-item.next-item.disabled .page-link,
.pagination .page-item.first.disabled .page-link,
.pagination .page-item.last.disabled .page-link {
  color: #6c757d;
}

.pagination .page-item:not(.prev-item) .page-link,
.pagination .page-item:not(.next-item) .page-link,
.pagination .page-item:not(.first) .page-link,
.pagination .page-item:not(.last) .page-link,
.pagination .page-item:not(.active) .page-link {
  line-height: 1.3;
}

.pagination .page-item.prev-item {
  margin-right: 0.3571rem;
}

.pagination .page-item.prev-item .page-link {
  background-color: #f0f0f0;
  padding: 0.572rem 0.651rem;
  border-radius: 50%;
}

.pagination .page-item.prev-item .page-link:before {
  content: "\e843";
  font-family: "feather";
}

.pagination .page-item.prev-item .page-link:hover {
  background: #7367f0;
  color: #ffffff;
}

.pagination .page-item.prev-item ~ .page-item:nth-child(2) {
  border-top-left-radius: 5rem;
  border-bottom-left-radius: 5rem;
}

.pagination .page-item.prev-item ~ .page-item:nth-child(2) .page-link {
  border-top-left-radius: 5rem !important;
  border-bottom-left-radius: 5rem !important;
}

.pagination .page-item.prev-item ~ .page-item:nth-last-child(2) {
  border-top-right-radius: 5rem;
  border-bottom-right-radius: 5rem;
}

.pagination .page-item.prev-item ~ .page-item:nth-last-child(2) .page-link {
  border-top-right-radius: 5rem !important;
  border-bottom-right-radius: 5rem !important;
}

.pagination .page-item.next-item {
  margin-left: 0.3571rem;
}

.pagination .page-item.next-item .page-link {
  background-color: #f0f0f0;
  padding: 0.572rem 0.651rem;
  border-radius: 50%;
}

.pagination .page-item.next-item .page-link:after {
  content: "\e844";
  font-family: "feather";
}

.pagination .page-item.next-item .page-link:hover {
  background: #7367f0;
  color: #ffffff;
}

.pagination .page-item.prev .page-link:before,
.pagination .page-item.previous .page-link:before {
  content: "\e843";
  font-family: "feather";
}

.pagination .page-item.next .page-link:after {
  content: "\e844";
  font-family: "feather";
}

.pagination .page-item .page-link {
  border: none;
  margin: 0;
  color: rgba(0, 0, 0, 0.6);
  background-color: #f0f0f0;
  margin-left: 0;
  font-size: 1rem;
  font-weight: 700;
}

.pagination .page-item .page-link i {
  position: relative;
  top: 1px;
}

.pagination .page-item .page-link:focus {
  box-shadow: none;
}

.pagination .page-item .page-link:hover {
  color: #7367f0;
}

.pagination .page-item.active {
  background-color: #f0f0f0;
  border-radius: 0;
}

.pagination .page-item.active .page-link {
  z-index: 3;
  border-radius: 5rem;
  background-color: #7367f0;
  color: #ffffff;
  -webkit-transform: scale(1.05);
  -ms-transform: scale(1.05);
  transform: scale(1.05);
}

.pagination .page-item:first-child:not(.prev-item).active {
  border-top-left-radius: 1.428rem;
  border-bottom-left-radius: 1.428rem;
}

.pagination .page-item:first-child:not(.prev-item).active .page-link {
  border-radius: 5rem;
}

.pagination .page-item:first-child:not(.prev-item) .page-link {
  border-top-left-radius: 1.428rem;
  border-bottom-left-radius: 1.428rem;
}

.pagination .page-item:last-child:not(.next-item).active {
  border-top-right-radius: 1.428rem;
  border-bottom-right-radius: 1.428rem;
}

.pagination .page-item:last-child:not(.next-item).active .page-link {
  border-radius: 5rem;
}

.pagination .page-item:last-child:not(.next-item) .page-link {
  border-top-right-radius: 1.428rem;
  border-bottom-right-radius: 1.428rem;
}

.pagination.pagination-lg .page-item .page-link {
  font-size: 1.5rem;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}

.modal .modal-header {
  background-color: #f8f8f8;
  border-radius: 0.42rem;
  padding: 0.8rem;
  border-bottom: none;
}

.modal .modal-header .close {
  padding: 0.2rem 0.62rem;
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.1);
  border-radius: 0.357rem;
  background: #ffffff;
  opacity: 1;
  -webkit-transition: all 0.23s ease 0.1s;
  transition: all 0.23s ease 0.1s;
  position: relative;
  -webkit-transform: translate(8px, -2px);
  -ms-transform: translate(8px, -2px);
  transform: translate(8px, -2px);
}

.modal .modal-header .close span {
  font-weight: 400;
  font-size: 2rem;
  color: #7367f0;
}

.modal .modal-header .close:hover,
.modal .modal-header .close:focus,
.modal .modal-header .close:active {
  opacity: 1;
  outline: none;
  -webkit-transform: translate(5px, 3px);
  -ms-transform: translate(5px, 3px);
  transform: translate(5px, 3px);
  box-shadow: none;
}

.modal .modal-header[class*="bg-"] {
  color: #ffffff;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.modal .modal-header[class*="bg-"] .modal-title {
  color: #ffffff;
}

.modal .modal-header.bg-primary .close span {
  color: #7367f0;
}

.modal .modal-header.bg-success .close span {
  color: #28a745;
}

.modal .modal-header.bg-info .close span {
  color: #00cfe8;
}

.modal .modal-header.bg-danger .close span {
  color: #dc3545;
}

.modal .modal-header.bg-warning .close span {
  color: #ff9f43;
}

.modal .modal-header.bg-dark .close span {
  color: #343a40;
}

.modal .modal-content {
  border: none;
  overflow: visible;
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.1);
}

.modal .modal-footer {
  border-color: rgba(0, 0, 0, 0.05);
}

.modal-sm {
  max-width: 400px;
}

.modal-xs {
  max-width: 300px;
}

.modal-xl {
  max-width: 94%;
  margin-left: 3%;
  margin-right: 3%;
}

@media (max-width: 576px) {
  .modal {
      padding-right: 1rem;
      padding-left: 1rem;
  }

  .modal .modal-xs,
  .modal .modal-sm {
      max-width: unset;
  }
}

.popover {
  z-index: 10;
}

.popover .popover-header {
  color: #ffffff;
  border: none;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
}

.popover.bs-popover-bottom .arrow:after {
  border-bottom-color: #7367f0;
}

.icon-bg-circle {
  color: #ffffff;
  padding: 0.5rem;
  border-radius: 50%;
}

.icon-left {
  margin-right: 0.5rem;
}

.icon-right {
  margin-right: 0.5rem;
}

.icon-spin {
  display: inline-block;
  -webkit-animation: spin 1s infinite linear;
  animation: spin 1s infinite linear;
}

.blockOverlay {
  z-index: 1050 !important;
}

.blockElement,
.blockPage {
  z-index: 1051 !important;
}

@-webkit-keyframes spin {
  from {
      -webkit-transform: rotate(0deg);
  }

  to {
      -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  from {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
  }

  to {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
  }
}

.hidden {
  display: none;
  visibility: hidden;
}

a:hover {
  cursor: pointer;
}

.round {
  border-radius: 1.5rem;
}

.square {
  border-radius: 0;
}

.icon-line-height {
  line-height: 1.5rem !important;
}

.table-middle td {
  vertical-align: middle;
}

.spinner {
  display: inline-block;
  -webkit-animation: spin 1s linear infinite;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
  }

  100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
  }
}

button:focus {
  outline: none;
}