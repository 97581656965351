.theme-9to5mac  {
    .card {
        .card-header {
            .card-title {
                h4 {
                    color: $blue;
                }
            }
        }
        .card-body {
            .loginBtn {
                box-shadow: 0px 5px 12px 0 rgba(40, 139, 206, 0.1);
                border: solid 1px rgba(40, 139, 206, 0.1);
                background-image: linear-gradient(to top, $blue, $blue);
            }
        }
    }
}
.alert.show {
    margin-bottom: 0;
}

.card-more-option {
    position: absolute;
    top: 23px;
    right: 0px;
    color: #5abc45;
} 
.home-qna-listing {
    .pagination {
        margin-top: 2rem;
    }
}
.frontend-vue-app {
    width: 100%;
}

.trending__questionsList {
    .question {
        a {
            display: block;
            width: 286px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            color: #000;
        }
    }
}
.questionTabsContainer {
    .questionTabsContainer__question {
        a {
            color:#000
        }
    }
}

.qna-list-item {
    position: relative;
}

.mainMenu__searchContainer {
    width: 36%;
    .mainMenu__search  {
        width: 100%;
    }
}
.iframe-webview-layout {
    background-color: #fff;
}
.questionTabsContainer__commentSection {
    .like-item {
        border-right: 1px solid #34cd86;
        width: 50px;
        text-align: center;
        display: inline-block;
        height: 42px;
        color: #dedede;
        padding-top: 12px;
    }
    .like-item.active {
        color: $green;
    }
}

.modal {
    .modal-header {
        background-color: #fff;
    }
    .modal-content {
        .card.rounded-0 {
            box-shadow: none;
        }
    }
}

.social-buttons {
    a {
        background-color: transparent !important;
        color: #333;
        &:hover {
            color: #333 !important;
        }
    }

    .btn-primary.google:hover {
        border-color: red !important;
        color: #333 !important;
        box-shadow: none;
    }
    .btn-primary.facebook:hover {
        border-color:#3a589b !important;
        color: #333 !important;
        box-shadow: none;
    }
}

.product-filter-tag {
    padding: 7px;
}
.logo-wrapper {
    img {
        width: 37%;
    }
}
.header-main {
    .logo {
        img {
            width: 85%;
        }

    }
  }

.like-item {color: #dcdfdd;}
.like-item.active {color:#4abc44;}

.QandA-section {
    .q-title {
        color: #202020;
        text-decoration: none;
    }
    .questionTabsContainer__img {
        padding: 1.5rem;
        border-radius: 72%;
        width: 72px;
        background-color: #dcdfdd;
        margin-right: 2px;
        text-transform: uppercase;
        color: #202020;
        text-align: center;
        font-weight: bolder;
        height: 72px;
        position: absolute;
        font-size: 17px;
        top: 0px;
    }
}

/*----Ask question --------------------------------------*/
#ask-question-div {
    .search-control {
        padding: 4px 0px !important;
        .select2-selection.select2-selection--single {
            border: none;
        }
    }
}

/*------------Search Result Page ------------------------*/
#search-result-page {
    ul.pagination {
        margin-top: 1rem;
    }
}

