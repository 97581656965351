body {
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
}

.bg-authentication {
  width: 540px;
  border-radius: 10px !important;
  box-shadow: 0px 17.5px 49px 0 rgba(24, 24, 24, 0.15);
  background-color: #fff;
}

.card .card-header .card-title h4 {
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  padding: 10px 30px;
  letter-spacing: 1.4px;
  text-align: left;
  color: #4abc44;
  text-transform: uppercase;
  text-align: center;
}

.login-auth .card .card {
  padding: 30px;
}

.login-auth label {
  font-family: Montserrat;
  font-size: 14px;
  line-height: 1.5;
  letter-spacing: 0.28px;
  text-align: left;
  color: #2a2a2a;
  font-weight: 600;
  padding-bottom: 7px;
}

a {
  color: #4abc44;
}

a:hover {
  color: #4abc44;
  text-decoration: none;
}

body.bg-full-screen-image.login-page {
  background: url(./images/backgrounds/login-bg.png) no-repeat
    center center;
}

.card .card-header {
  justify-content: center;
}

.has-icon-left .form-control {
  border-radius: 5px;
  border: solid 1px rgba(70, 169, 69, 0.16);
  height: 50px;
}

.has-icon-left .form-control {
  padding-left: 2.5em;
}

.form-control::-webkit-input-placeholder {
  color: #888888;
  font-size: 13px;
  font-weight: 500;
}

.form-control::-moz-placeholder {
  color: #888888;
  font-size: 13px;
  font-weight: 500;
}

.form-control:-ms-input-placeholder {
  color: #888888;
  font-size: 13px;
  font-weight: 500;
}

.form-control::-ms-input-placeholder {
  color: #888888;
  font-size: 13px;
  font-weight: 500;
}

.form-control::placeholder {
  color: #888888;
  font-size: 13px;
  font-weight: 500;
}

.form-control-position {
  line-height: 3.2rem;
}

.rememberMe a {
  letter-spacing: 0.28px;
  text-align: left;
  color: #444444;
  font-size: 14px;
  font-weight: 400;
}

.rememberMe .checkbox {
  letter-spacing: 0.28px;
  color: #444444;
  font-size: 14px;
  font-weight: 400;
}

.vs-checkbox-con .vs-checkbox {
  border-color: #4abc44;
}

.loginBtn {
  width: 100%;
  border-radius: 5px !important;
  box-shadow: 0px 5px 12px 0 rgba(74, 188, 68, 0.1);
  border: solid 1px rgba(70, 169, 69, 0.16);
  background-image: linear-gradient(to top, #449f46, #4abc44);
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  padding: 15px 30px;
}

.btn-primary:hover {
  border-color: #49b544 !important;
}

.registerText {
  padding-top: 30px;
  font-size: 14px;
  font-weight: 400;
  color: #111111;
  clear: both;
}

.registerAccount .vs-checkbox-con input {
  width: 8%;
}

.registerText a {
  color: #111111;
}

.vs-checkbox-con input:checked ~ .vs-checkbox .vs-checkbox--check .vs-icon {
  line-height: 16px;
  font-size: 18px;
}

.registerAccount
  .vs-checkbox-con
  input:checked
  ~ .vs-checkbox
  .vs-checkbox--check
  .vs-icon {
  line-height: 22px;
  font-size: 22px;
}

.registerAccount .vs-checkbox-con .vs-checkbox {
  margin-top: -8px;
  width: 30px;
  height: 26px;
}

.registerAccount .rememberMe .checkbox {
  font-weight: 400;
}

.main-menu .navigation > li > a > span {
  color: #222;
}

.main-menu.menu-light .navigation > li.active {
  box-shadow: inset -1px 0px 0 0 #4abc44;
}

.main-menu.menu-light .navigation > li.active a {
  background: #f3f8ec !important;
  box-shadow: none !important;
}

.main-menu.menu-light .navigation li a {
  color: #222;
}

.main-menu.menu-light .navigation > li {
  padding: 0 2px;
  margin-bottom: 1em;
}

.main-menu .navigation > li > a > img {
  margin-right: 1rem;
  float: left;
}

.main-menu .navbar-header,
.header-navbar {
  min-height: 5rem;
  height: auto;
  border-bottom: 1px solid #e5f1d4;
  border-right: 1px solid #e5f1d4;
}

.main-menu .navbar-header .navbar-brand {
  margin-top: 0px;
}

.app-content.content .content-header .content-header-title {
  font-weight: 700;
  color: #222;
  font-size: 18px;
  margin-right: 1rem;
}

.breadcrumbs-top .breadcrumb {
  border: none;
}

.breadcrumbs-top .breadcrumb {
  padding-left: 0px !important;
}

.header-navbar .navbar-container ul.nav li .badge.badge-up {
  position: absolute;
  top: 10px;
  right: -3px;
  font-size: 10px;
}

.breadcrumb .breadcrumb-item + .breadcrumb-item:before {
  content: "\e844";
  font-family: "feather";
  color: #4abc44;
}

li.breadcrumb-item a {
  color: #777777;
}

li.breadcrumb-item img {
  margin-right: 5px;
  margin-top: -3px;
}

.dropdown-notification .dropdown-menu-header {
  background: #4abc44;
}

.dropdown-notification .dropdown-menu.dropdown-menu-right::before {
  background: #4bbc45;
  border-color: #4bbc45;
}

.dropdown-header {
  color: #fff;
}

html body.navbar-sticky .app-content .content-wrapper {
  padding: 2.2rem 1.5rem;
  margin-top: 4.65rem;
}

i.feather.icon-more-horizontal {
  display: flex;
  justify-content: center;
  color: #000 !important;
}

table.data-list-view td a.dropdown-item {
  color: #222 !important;
  padding-top: 10px;
}

.actionTd {
  padding-right: 5px !important;
}

.pagination .page-item.active .page-link {
  background-color: #4abc44;
}

.dropdown-item.active,
.dropdown-item:active {
  background-color: #4abc44;
  color: #fff;
}

.primary-process {
  color: #30befb !important;
}

.success-process {
  color: #34cd86 !important;
}

.danger-process {
  color: #ff3e6c !important;
}

.warning-process {
  color: #ffcc3e !important;
}

ul,
li {
  list-style: none;
  padding: 0;
}

.font_large {
  font-size: 1.8rem;
}

.pad_1 {
  padding: 1rem;
}

.table-fix {
  display: block;
  width: 100%;
  overflow: auto;
}

.justify__between {
  justify-content: space-between !important;
}

.justify__content__left {
  justify-content: left !important;
}

.justify__content__end {
  justify-content: flex-end;
}

.align__items__center {
  align-items: center;
}

.disp-flex {
  display: flex;
}

.color-primary {
  color: #4abc44;
}

.color-blue {
  color: #005ddb;
}

.txt-center {
  text-align: center;
}

.txt-left {
  text-align: left;
}

.txt-right {
  text-align: right;
}

.txt-end {
  text-align: end;
}

.small-width {
  width: 16rem;
}

.pd-1 {
  padding: 1rem !important;
}

.pd-0 {
  padding: 1px;
}

.max-width {
  width: 100%;
}

.disp-block {
  display: block;
}

.text-bold {
  font-weight: bolder;
  color: #000;
}
/* ---------------------  Custom Css Starts Here 5th December 2020 ------------------------------------ */
body {
  font-family: "Roboto", sans-serif;
}

.mainMenu {
  display: flex;
  background: #fff;
  justify-content: space-between;
  align-items: center;
  padding: 0.7rem 10%;
}

.mainMenu__itemContainer {
  list-style: none;
  margin: 0;
}

.mainMenu__item {
  display: inline-block;
  padding: 0.5rem;
}

.mainMenu__item:not(:last-child) {
  border-bottom: 1px solid #fff;
}

.mainMenu__item__link {
  color: #222222;
  font-weight: 400;
  font-family: "Roboto", sans-serif;
}

.mainMenu__search {
  border-radius: 25px;
  border: none;
  background: #4abc44;
  padding: 5px 10px;
  padding-left: 2rem;
  color: #fff;
  outline: none;
  font-size: 14px;
}

.mainMenu__search::placeholder {
  color: #fff;
}

.mainMenu__searchContainer {
  position: relative;
}

.mainMenu__searchContainer::before {
  content: url("./images/icons/search.png");
  position: absolute;
  top: 6px;
  left: 10px;
  display: none;
}

.mainMenu__btn {
  text-transform: uppercase;
  color: #999999;
  padding: 0.5rem;
  margin-right: 5px;
  font-size: 14px;
}

.mainMenu__btn--login::before {
  content: url("./images/icons/loginIcon.png");
  margin-right: 4px;
}
.mainMenu__btn--join::before {
  content: url("./images/icons/joinIcon.png");
  margin-right: 4px;
}

.questionForm__container {
  display: flex;
  justify-content: space-between;
}

.main__heading {
  font-size: 23px;
  line-height: 45px;
  color: #232323;
  font-weight: 500;
}

/* .body__container {
 
} */

.btn {
  display: inline-block;
  padding: 3px 1rem;

  background: #fff;
  border-radius: 30px;
}

.btn__primary {
  background-color: #4abc44;
}

.btn__primary a {
  color: #fff;
}

.btn__secondary a {
  color: rgb(17, 17, 17);
}

.questionForm__container {
  border-bottom: 2px solid #c7e4c5;
}

.inner__body__wrapper {
  padding: 1rem 16%;
}

.questionForm__btnContainer {
  padding-top: 0.5rem;
}

.custom__tab__nav {
  background: #fff;
}

.custom__tab__nav .nav-link.active {
  color: #4abc44 !important;
}

.custom__tab__nav.nav-tabs .nav-item .nav-link.active::after {
  width: 55% !important;
  left: 50% !important;
  transform: translateX(-50%) !important;
  background-color: #4abc44 !important;
  top: 37px;
}

.questionTabsContainer {
  background: #fff;
  padding: 1rem;
  position: relative;
  border: 1px solid #c7e4c5;
  border-top-right-radius: 5px;
    border-top-left-radius: 5px;
}

.questionTabsContainer:not(:first-child) {
  margin-top: 3rem;
}

/*.questionTabsContainer::before {
   content: url("../images/icons/closeIcon.png"); 
  content: "x";
  font-size: 1rem;
  font-weight: 600;
  position: absolute;
  top: 1rem;
  right: 1rem;

  cursor: pointer;
}*/

.questionTabsContainer__img {
  padding: 0.6rem;
  border-radius: 50%;
  background-color: #4abc44;
  margin-right: 2px;
  color: #fff;
}

.questionTabsContainer__name,
.questionTabsContainer__question,
.question {
  color: #000000;
  font-weight: 600;
}
.questionTabsContainer__iconContainer__replyIcon, .questionTabsContainer__iconContainer__messageIcon,
.questionTabsContainer__iconContainer__messageChat{
  border-right: 1px solid #34cd86;
  width: 50px;
  text-align: center;
  display: inline-block;
  height: 42px;
    padding-top: 12px;
}
.questionTabsContainer__iconContainer__replyIcon::before {
  content: url("./images/icons/aro.png");
}
.questionTabsContainer__iconContainer__messageIcon::before {
  content: url("./images/icons/he.png");
}
.questionTabsContainer__iconContainer__messageChat::before {
  content: url("./images/icons/ch.png");
}

.questionTabsContainer__commentSection {
  margin-top: 0;
    background: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    border: 1px solid #c8dcc7;
    border-top: none;
}

.questionTabsContainer__commentSection__form {
  width: 65%;
  position: relative;
  margin: 7px 15px;
}
.hearts {
  width: 35%;
}

.questionTabsContainer__commentSection__comment {
  width: 100%;
  border-radius: 30px;
  padding: 0.3rem 3rem;
  outline: none;
  border: none;
  padding-right: 31%;
  background: #f0f0f0;
}

.questionTabsContainer__commentSection__button {
  position: absolute;
  top: 0;
  right: 0;
}

.trending__questions__heading {
  background: #fff;
  font-size: 14px;
  color: #000;
  padding: 1rem;
  margin-top: 1.3rem;
  text-align: center;
}

.trending__questionsList__item {
  background: #fff;
  padding: 1rem;
}

.trending__questionsList__item:not(:last-child) {
  border-bottom: 1px solid #e5f1d4;
}

.trending__questionsList__item p {
  margin-bottom: 5px;
}

.trending__questionsList__askedBy {
  font-size: 12px;
}

.readMore__container {
  text-align: right;
  padding: 0 2rem;
}

.section__footer {
  background: #fff;
  padding: 1rem;
  border-top: 1px solid #c7e4c5;
}

.footer__list {
  list-style: none;
  text-align: center;
  margin:0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer__item {
  display: inline-block;
  padding: 0.5rem;
  color: #626262;
}

.readMore__container {
  margin-top: 2rem;
  padding: 0;
}

/* ///// 7 December 2020 ////// */

.mainMenu__content__wrapper {
  display: flex;
  justify-content: space-between;
  width: 70%;
  align-items: center;
}

.main__menu__hamburgerIcon {
  display: none;
}

.only__mobile__show {
  display: none;
}
.learn-how {
  border: 1px solid #787878;
}
ul#myTab2 {
  border: 1px solid #c7e4c5;
  border-radius: 5px;
}
.nav-tabs .nav-item {
  margin-bottom: 0!important;
}
.nav.nav-tabs .nav-item .nav-link {
  padding: 10px 3px;
}
.main-div {
  border: 1px solid #c7e4c5;
  border-radius: 5px;
  margin-top: 16px;
}
.extra .questionTabsContainer__iconContainer__replyIcon, .extra .questionTabsContainer__iconContainer__messageIcon, 
.extra .questionTabsContainer__iconContainer__messageChat {
  border-right: none;
  width: 50px;
  text-align: center;
  display: inline-block;
  height: auto;
  padding-top: 0;
}
.bo-b {
  border-bottom: 1px solid #c7e4c5!important;
}

@media only screen and (max-width: 900px) {
  .mainMenu {
    flex-direction: column;
  }

  .mainMenu__itemContainer {
    width: 100%;
    padding: 1rem 0;
    margin: 1rem 0;
    background: #f8f8f8f8;

    display: none;
  }

  .mainMenu__item {
    display: block;
    text-align: left;
    padding-left: 5vw;
  }

  .mainMenu__logoContainer {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .mainMenu__buttonContainer {
    margin-top: 0.5rem;
  }

  .mainMenu__searchContainer {
    margin: 0.5rem 0;
  }

  .main__menu__hamburgerIcon {
    height: 20px;
    width: 20px;
    cursor: pointer;
    display: block;
  }

  /* // Menu Responsive End // */
  .questionForm__container {
    display: flex;
    flex-direction: column;
  }

  .questionForm__btnContainer .btn {
    margin: 0.5rem 0;
  }

  .questionTabsContainer__commentSection {
    display: flex;
    flex-direction: column;
    align-items: baseline;
  }

  .questionTabsContainer__commentSection__form {
    width: 100%;
  }

  .questionTabsContainer__commentSection__button {
    position: relative;
    margin: 0.5rem 0;
  }

  .questionTabsContainer__commentSection__comment {
    padding: 0.5rem;
    margin: 0.5rem 0;
  }

  .mobile__hide {
    display: none;
  }

  .only__mobile__show {
    display: block;
  }

  .mainMenu__searchContainer,
  .mainMenu__search {
    width: 100%;
  }

  .mainMenu__searchContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 1rem 0;
  }

  .mobile__menu__search__icon {
    display: inline-block;
    height: 25px;
  }
  #mainMenu__searchForm--js {
    display: none;
  }

  .mainMenu__searchForm {
    width: 100%;
  }

  .mainMenu__search {
    padding: 0.5rem 1rem;
  }

  .mainMenu__searchContainer::before {
    content: "";
  }

  .questionTabsContainer__img {
    display: inline-block;
  }

  .custom__tab__nav {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .custom__tab__nav .nav-item {
    width: 100% !important;
  }

  .mainMenu__iconContainer img {
    display: inline-block;
    margin-left: 1rem;
  }

  .inner__body__wrapper {
    padding: 1rem;
  }

  .card-body {
    padding: 0.5rem;
  }
}

.no__background {
  background: none;
}

.no__after::after {
  content: "";
}
.no__before::before {
  content: "";
}
.no__margin {
  margin: 0 !important;
}

.secondary__heading {
  font-size: 1.5rem;
  margin: 1rem 0;
}

.question__heading {
  font-size: 1.8rem;
  margin: 1rem 0;
}

.add__yourAnswer {
  margin: 1rem 0;
  margin-bottom: 2em;
}

.add__yourAnswer__textarea {
  display: block;
  width: 100%;
  outline: none;
  border: none;
  border-bottom: 1px solid #d6d6d6;
  /* background-color: #f8f8f8; */
  margin-bottom: 1.5rem;
  padding: 1rem;
  resize: none;
}

.queston__container2 {
  background-color: #fff;
  border: none;
  margin: 0 !important;
  padding: 1.5rem !important;
}

.queston__container2.sub__question__container {
  padding-left: 10% !important;
}

.main__questionsAnswers {
  margin-top: 1.5rem;
}

.white__background {
  background: #fff;
}

.leaveYourAnswer__text {
  font-size: 1.5rem;
  margin: 0;
}

/*  Abdul Basit CSS */
.nav-bg {
    background: #2b2b2b;
    padding: 0;
}
.header-main {
  background: #fff;
  padding: 20px 0;
  border-bottom: 1px solid #eaebef;
}
.nav-bg a.nav-link {
  font-size: 16px;
  color:  #d6d4d4!important;
}
.nav-bg ul {
  text-align: center;
  align-items: center;
  justify-content: center;
  max-width: 100%;
  width: 100% ;
}
.nav-bg ul li{
  padding: 0 10px;
}
.mainMenu__searchContainer {
  width: 100%!important;
  max-width: 240px;
  float: right;
}
form.main__searchForm {
  margin-top: 15px;
}
form.main__searchForm  .mainMenu__search {
  padding: 5px 30px;
  background: transparent;
  border: none;
  color: #666;
  font-size: 16px;
}
.mainMenu__searchContainer::before {
  top: 23px;
}
form.main__searchForm  .mainMenu__search::-webkit-input-placeholder { color: #666;}
form.main__searchForm  .mainMenu__search:-ms-input-placeholder { color:#666;}
form.main__searchForm  .mainMenu__search::placeholder {color:#666;}
.mainMenu__buttonContainer {
  margin-top: 20px;
  float: right;
}
.qna-banner {
  background-image: url("./images/q-banner.jpg");
  background-size: cover;
  min-height: 400px;
  background-repeat: no-repeat;
}
.qna-banner h1{
  font-size: 50px;
  font-weight: 300;
  color: #222222;
  text-align: left;
  margin: 125px auto;
}
html body {
  overflow-x: hidden;
}
.tranding-section .trend-list {
  margin: 30px 0;
  background: #fff;
  display: flex;
  border-radius: 5px;
  border: 1px solid #cde0cc;
  padding: 10px;
  box-shadow: 0px 0px 15px 2px #ddd;
}
.tranding-section .trend-list a{
  font-size: 16px;
  color: #787878;
  padding: 5px 15px;
}
.tranding-section .trend-list a:hover{
  color: #4abc44;
  text-decoration: none;
}
.gadgets-section .gadgets-list {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0;
}
.gadgets-section .gadgets-list li{
  background-color: #fff;
  padding: 40px 20px;
  border-radius: 10px;
  border: 1px solid #cde0cc;
  box-shadow: 0px 0px 15px 2px #ddd;
}
.gadgets-section .gadgets-list hr {
  margin-top: 2rem;
  border-top: 1px solid #e1f1e1;
}
.gadgets-section .gadgets-list a {
  font-size: 16px;
  font-weight: 700;
  color: #222;
  text-align: center;
  display: block;
  margin: 0 auto;
}
.questions-section {
  margin: 30px 0 0;
}
.questions-section img{
  max-width: 100%;
}
.questions-section .big-sec {
  background-color: #fff;
  padding: 20px;
}
.questions-section ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.questions-section ul .item{
  max-width: 260px;
  width: 100%;
  margin-bottom: 20px;
}
.questions-section ul>li {
  flex: 0 50%;
}
.QandA-section ul{
  margin: 0 0 30px;
  background: #fff;
  display: flex;
  border-radius: 0;
  box-shadow: 0px 0px 15px 2px #ddd;
  flex-direction: column;
}
.questions-section ul li h4 {
  font-size: 19px;
  font-weight: 500;
  color:#222;
}
.questions-section ul li h6 {
  font-size: 12px;
  font-weight: 500;
  color: #449f46;
  margin: 10px 0;
}
.questions-section ul h6 span{
  font-weight: 300;
  font-size: 11px;
  color:#787878;
  padding-left: 6px;
}
.questions-section ul li p {
  font-size: 13px;
  font-weight: 400;
  color: #449f46;
  margin: 0;
}
.questions-section .big-sec.main {
  background-color: #fff;
  padding: 30px 40px 0;
  min-height: 170px;
}
.big-sec.main h6 {
  font-size: 16px;
  font-weight: 500;
  color: #449f46;
  margin: 0 0 7px;
}
.big-sec.main h6 span{
  font-weight: 300;
  font-size: 13px;
  color:#787878;
  padding-left: 6px;
}
.big-sec.main p {
  font-size: 16px;
  font-weight: 400;
  color: #449f46;
  margin: 0;
}
li.row {
  max-width: 100%;
  width: 100%;
  padding: 25px 20px;
  border-bottom: 1px solid #eaeaea;
}
.QandA-section h6 {
  font-size: 16px;
  font-weight: 400;
  color: #449f46;
  margin: 0 0 7px;
}
.QandA-section h6 span{
  font-weight: 300;
  font-size: 13px;
  color:#787878;
  padding-left: 10px;
}
.QandA-section .QandA-ans {
  font-size: 16px;
  font-weight: 700;
  color: #222;
  text-align: center;
  border: 1px solid #d3e6d2;
}
.QandA-section .QandA-ans p{
  border-bottom: 1px solid #d3e6d2;
  margin: 0;
  padding: 5px;
}
.QandA-section .QandA-ans span{
  padding: 5px;
  display: block;
}
.QandA-section .img-circle {
  border: 1px solid #d3e6d2;
  padding: 6px;
}
.nolp {
  padding-left: 0!important;
}
.QandA-section .b-r-l span {
  border-right: 1px solid #d3e6d2;
  padding: 0 15px;
}
.QandA-section .b-r-l span:last-child {
  border: none;
}
.pagination {
  margin-bottom: 30px;
}
.gadgets-section .gadgets-list a:hover{
  text-decoration: none;
}
.top-bar {
  background: #4abd43;
}
.top-bar ul{
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 5px 0;
}
.top-bar ul li{
  margin-left: 12px;
}
.header-text p{
  font-size: 16px;
  margin-bottom: 3px;
}
.header-text .call {
  font-size: 24px;
  color: #35bf4f;
  line-height: 24px;
  font-weight: 600;
}
.header-text .call a:hover{
  text-decoration: none;
}
.user-link {
  float: right;
  margin-top: 15px;
}
.user-link:hover {
text-decoration: none;
}
#login-dp {
  padding: 10px;
}
.dropdown-toggle::after {
  display: none;
}
#navbarSupportedContent .dropdown {
  float: left;
  position: unset;
}
#navbarSupportedContent .dropdown .dropbtn {
  border: none;
  outline: none;
  color:#d6d4d4;
  margin: 0;
  font-size: 16px;
  background: #2b2b2b;
  padding: 15px 0;
}
#navbarSupportedContent .navbar a:hover, #navbarSupportedContent .dropdown:hover .dropbtn {
 background-color: #2b2b2b;
}
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #fff;
  width: 100%;
  left: 0;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 999;
  top: 47px;
}
.dropdown:hover .dropdown-content {
  display: block;
}
.dropdown-content .row {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 25px 0;
  max-height: 100%;
  min-height: 100%;
  overflow: auto;
  -ms-scroll-chaining: none;
  overscroll-behavior: none;
  width: 100%;
}
.dropdown-content .column {
  float: left;
  padding: 10px;
  margin: 0 auto;
}
#navbarSupportedContent .column li {
  padding:0;
}
#navbarSupportedContent .column a {
  float: none;
  color: #838383;
  padding: 8px 0;
  text-decoration: none;
  display: block;
  text-align: left;
  font-size: 14px;
  font-weight: 400;
}
#navbarSupportedContent .column h3 {
  color: #228118;
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 15px;
  text-align: left;
}
#navbarSupportedContent .column h3 a{
  color: #228118;
  font-size: 16px;
  font-weight: 700;
  text-align: left;
  padding: 0;
}
#navbarSupportedContent .div {
  width: 196px;
  min-width: 196px;
  transition: all 0.2s ease 0s;
  color: #838383;
  margin: 0 auto;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
}
#navbarSupportedContent .div h3{
  position: relative;
}
#navbarSupportedContent .div h3:after {
  height: 4px;
  margin: 0;
  background: #228118;
  border-radius: 2px;
  transition: all 0.2s ease 0s;
  position: absolute;
  bottom: -12px;
  left: 0;
  width: 160px;
  content: "";
}
/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}
.desktop__hide {
  display: none;
}
h3.question-text {
  line-height: 30px;
}
.trending__questionsList .question a {
  width: auto!important;
  white-space: unset!important;
  text-overflow: unset!important;
}

h2.text-success{
  color : #228118;
}
select#exampleFormControlSelect1 {
  /* width: 30%; */
  border: none;
  margin-top: -8px;
}
.product_bss .col-md-6, .product_bss .col-md-4, .product_bss img {
  min-height: 200px;
}
.product_bss .col-md-4{
  flex-direction: column;
}
.select2-container--default .select2-selection--single .select2-selection__placeholder {
  color: #5f5f5f!important;
}
.modal-body {
  max-height: initial;
}
.modal-body {
  padding: 2rem 1rem;
}
.social-buttons a {
  height: 40px;
  line-height: 32px;
}
button.close {
  z-index: 9999!important;
  position: absolute!important;
  right: 10px!important;
  top: 10px!important;
}
.big-sec h4 a{
  color: #202020!important;
}
.qna-banner h1 span {
  font-weight: 700;
}
#ask-question-div form.main__searchForm {
  margin-top: 0;
}
.device-listing {
  background: transparent!important;
  box-shadow: none!important;
  display: flex!important;
  flex-wrap: wrap!important;
  flex-direction: row!important;
}
.device-listing li{
  list-style: none;
  flex: 0 0 33.333333%;
  position: relative;
  padding-left: 12px;
}
.device-listing li:before{
  position: absolute;
  top: 12px;
  left: 0;
  content: "";
  background: #222;
  width: 5px;
  height: 5px;
  border-radius: 50%;
}
.device-listing li a{
  line-height: 28px;
}
.devices-heading2 {
  margin-bottom: 20px;
}
.devices-heading6 {
  font-weight: 700!important;
}

@media screen and (max-width: 992px) {
  .desktop__hide {
    display: block!important;
  }
  button.navbar-toggler {
    float: right;
  }
}

@media screen and (max-width: 767px) {
.header-main {
  text-align: center;
}
.mainMenu__searchContainer {
  margin: 0;
}
form.main__searchForm {
  max-width: 100%;
  width: 100%;
}
.gadgets-section .gadgets-list {
  flex-direction: column;
}
.gadgets-section .gadgets-list li {
  max-width: 100%;
  width: 100%;
  text-align: center;
  margin-bottom: 15px;
}
.qna-banner h1 {
  font-size: 26px;
    text-align: center;
}
.qna-banner {
  min-height: auto;
}
.tranding-section .trend-list {
  flex-direction: column;
  text-align: center;
}
.tranding-section .trend-list li{
  margin: 5px 0;
}
.questions-section ul .item {
  max-width: 100%;
}
.questions-section ul > li {
  flex: 0 100%;
}
.questions-section img {
  max-width: 100%;
  width: 100%;
}
li.row {
  text-align: center;
}
.b-r-l {
  margin: 20px 0;
}
.QandA-section h3 {
  margin: 15px 0;
}
.questions-section .big-sec.main {
  padding: 20px;
}
.mainMenu__searchContainer {
  width: 100% !important;
  max-width: 100%;
  float: unset;
}
form.main__searchForm {
  margin-top: 0;
}
.nav-bg button{
  margin-top: -115px;
}
.navbar {
  padding: 0 1rem;
}
.header-main {
  padding: 10px 0 0;
}
.column {
  width: 100%;
  height: auto;
}
.nav-bg {
  display: none;
}
.desktop__hide {
  display: block!important;
}
button.navbar-toggler {
  float: right;
}
.navbar-light .navbar-nav .nav-link {
  color: #666;
  font-size: 17px;
  padding: 10px 0;
}
.megamenu {
  position: static
}
.megamenu .dropdown-menu {
  background: none;
  border: none;
  width: 100%;
  box-shadow: none!important;
}
.dropdown .dropdown-menu::before {
  display: none!important;
}
.navbar-collapse {
  margin-top: 15px;
}
a.mobile-link {
  font-size: 14px;
  color: #666;
  line-height: 24px;
}
.navbar-nav h3 {
  font-size: 16px;
}
.select2-container {
  max-width: 100%;
  width: 100%;
}
.page-item + .page-item {
  margin-left: 0.2rem;
}
.pagination .page-link {
  min-width: 22px;
}
.page-link {
  padding: 0.5rem 0.3rem;
}
.QandA-section .questionTabsContainer__img{
  position: relative;
}
.desktop__hide img.navbar-brand-image {
  max-width: 200px;
}
.QandA-section .questionTabsContainer__img {
  position: relative!important;
}
.footer__list {
  flex-direction: column;
}
li.row {
 margin: 0 auto;
}
.card-more-option {
  right: 6px!important;
}
.desktop__hide .megamenu {
  position: relative;
}
.desktop__hide .megamenu::after {
  position: absolute;
  top: 10px; 
  right: 5px;
  width: 12px;
  height: 8px;
  font-family: "Font Awesome 5 Free";
  content: "\f0dd";
  color: #666;
  display: inline-block;
   font-weight: 900; 
}
.device-listing li {
  flex: 0 0 100%;
}
}






